import React, { useContext, useEffect, useRef, useState } from "react";
import "../MenuLateral/index.css";
import {
  faStream,
  faCog,
  faHeadset,
  faFunnelDollar,
  faBullhorn,
  faHotel,
  faArrowLeft,
  faTicketAlt,
  faCommentAlt,
  faHeadphonesAlt,
  faEnvelope,
  faPeopleArrows,
  faCalendarMinus,
  faTimes,
  faComment,
  faBlenderPhone,
  faCalendar,
  faArrowRight,
  faUserTie,
  faPhoneAlt,
  faTh,
  faBuilding,
  faPlusCircle,
  faUser,
  faCopy,
  faUsers,
  faListAlt,
  faMap,
  faBookmark,
  faArrowsAltH,
  faPencilAlt,
  faCodeBranch,
  faTemperatureHigh,
  faAlignLeft,
  faClock,
  faPlug,
  faLocationArrow,
  faFilter,
  faHistory,
  faRobot,
  faBellConcierge,
  faFileAlt,
  faDollarSign,
  faChartLine,
  faChartBar,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menu from '../../assets/navigation-menu.svg';
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { faWhatsapp, faFacebookSquare, faLinkedin, faChrome, faGoogle } from "@fortawesome/free-brands-svg-icons";
import guService from "../../services/GuService";
import ModalDommus from "../Modal";
import { NovaOportunidade } from "../../pages/Oportunidades/NovaOportunidade";
import empreendimentoService from "../../services/EmpreendimentoService";
import oportunidadeService from "../../services/OportunidadeService";
import ConfiguracoesGestaoService from "../../services/ConfiguracoesGestaoService";
import {Logout} from './Subcomponents/Logout';
import { EmpreendimentoContext, OportunidadeContext } from "../GlobalContext/globalContext";
import { Submenu } from "./Subcomponents/Submenu";
import { cachearContexto } from "../../helpers/cachearContexto";
import { swalErro } from "../../helpers/DommusSwal";
import { TagOportunidadeContext } from "../../contexts/TagOportunidadeContext";
import TagOportunidadeService from "../../services/TagOportunidadeService";
import { UsuarioContext } from "../../contexts/UsuarioContext";
import Icon from "@iconify/react";
import roundDashboard from '@iconify-icons/ic/round-dashboard';
import { isMobile, isTablet } from "react-device-detect";
import PesquisaGeralInput from "../PesquisaGeralInput";

export default function MenuTopo({openMenu=false}) {
  const [open, setOpen] = useState(openMenu);
  const [openAtendimento, setOpenAtendimento] = useState(false);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(false);
  const [openMapeamento, setOpenMapeamento] = useState(false);
  const [openMaisFunc, setOpenMaisFunc] = useState(false);
  const [openModalNovaOportunidade, setOpenModalNovaOportunidade] =useState(false);
  const classeAsideSidebar = "sidebar" + (open ? "" : " hide");
  const classeSidebarWrapper = "sidebar-wrapper" + (open ? "" : " is-closed");
  const [maisFuncionalidades, setmaisFuncionalidades] = useState([])
  const [configuracoes, setconfiguracoes] = useState([])
  const contextoGlobalEmpreendimentos = useContext(EmpreendimentoContext)
  const [permissaoCamposDinamicos, setPermissaoCamposDinamicos] = useState([])
  const contextoGlobalOportunidades = useContext(OportunidadeContext)
  const refBtnConfiguracao = useRef();
  const refBtnMapeamento = useRef();
  const refMapeamento = useRef();
  const refBtnMaisFunc = useRef();
  const refBtnAtendimentos = useRef();
  const { empreendimentos, setEmpreendimentos } = useContext(EmpreendimentoContext)
  const { origens, setOrigens } = useContext(OportunidadeContext)
  const {listaTagsOportunidade, setListaTagsOportunidade} = useContext(TagOportunidadeContext);
  const { permissoes, validarPermissaoAcessoTela } = useContext(UsuarioContext)

  useEffect(() => {
    cachearContexto(empreendimentos, setEmpreendimentos, empreendimentoService.buscarEmpreendimentos)
    cachearContexto(origens, setOrigens, oportunidadeService.buscarOrigens)
    cachearContexto(listaTagsOportunidade, setListaTagsOportunidade, TagOportunidadeService.buscarTags)
    buscarConfiguracaoPorChave('gestao_campos_dinamicos')
    ordenarMenu()
  }, []);

  function buscarConfiguracaoPorChave(chave) {
    ConfiguracoesGestaoService.buscarConfiguracao(chave)
      .then(response => setPermissaoCamposDinamicos(response.data))
      .catch(error => {
        console.log(error)
        console.log(error.response)
        swalErro('Houve um erro ao tentar buscar as permissões de campos dinamicos.')
      })
  }

  function buscaPath() {
    return window.location.pathname === "/"
  }

  function ordenarMenu(){
    let maisfuncionalidades = [
      {
        nome: 'Meu Perfil',
        icon: faUser,
        link: '/perfil',
        color: "#4caf50"
      },
      {
        nome: 'Campanhas',
        icon: faBullhorn,
        link: '/campanha',
        color: "#3f51b5"
      },
      {
        nome: 'Leads Represados',
        icon: faUser,
        link: '/leads/represados',
        color: "#F44336"
      },
      {
        nome: 'Historico de Distribuição',
        icon: faHistory,
        link: '/leads/historico-distribuicao',
        color: "#FD7E14"
      },
      {
        nome: 'Oferta Ativa',
        icon: faBlenderPhone,
        link: '/oferta-ativa',
        color: "#4caf50"
      },
      {
        nome: 'Filas de Atendimento',
        icon: faPeopleArrows,
        link: '/filas-atendimento',
        color: "#bdd62f"
      },
      {
        nome: 'Agenda do Usuário',
        icon: faHotel,
        link: '/agenda-do-usuario',
        color: "#FF865E"
      },
      {
        nome: 'Acompanhamento Online',
        icon: faPlug,
        link: '/tempo-online',
        color: "#183153"
      },

      {
        nome: 'Acompanhamento de Históricos',
        icon: faClock,
        link: '/acompanhamentos-historicos',
        color: "#183153"
      },

      {
        nome: 'Acompanhamento da Régua de Nutrição',
        icon: faListAlt,
        link: '/acompanhamentos-regua-nutricao',
        color: "#183153"
      },

      {
        nome: 'Aprovar solicitações',
        icon: faCopy,
        link: '/solicitacoes',
        color: "#71DFE7"
      },
      {
        nome: 'Gestão dos Usuários',
        icon: faUsers,
        link: '/perfis-usuarios',
        color: "#000"
      },
      {
        nome: 'Campos de Qualificação',
        icon: faPencilAlt,
        link: '/campos-qualificacao',
        color: "#4caf50"
      },
      {
        nome: 'Relatorios Diversos',
        icon: faChartBar,
        link: '/relatorio/diversos',
        color: "#FD7E14"
      },
      {
        nome: 'Régua de Nutrição',
        icon: faListAlt,
        link: '/regua-nutricao',
        color: "#E04DB0"
      },
      {
        nome: 'Últimas Atualizações',
        icon: faCodeBranch,
        link: '/atualizacoes',
        color: "#3f51b5"
      },
      {
        nome: 'Central de Recepção de Leads',
        icon: faBellConcierge,
        link: '/leads/central-recepcao-leads',
        color: "#fdb833"
      },
      {
        nome: 'Investimento em Mídias',
        icon: faDollarSign,
        link: '/investimento-midias',
        color: "#219ebc"
      }
    ]

    let configuracoes = [
      {
        nome: 'Mídias',
        icon: faGoogle,
        link: '/configuracoes/midia',
        color: "#142F43"
      },
      {
        nome: 'Motivos de desistência',
        icon: faTimes,
        link: '/configuracoes/motivos_desistencia',
        color: "#FF6D6D"
      },
      {
        nome: 'Funil de vendas',
        icon: faFunnelDollar,
        link: '/configuracoes/funil-de-vendas',
        color: "#34BE82"
      },
      {
        nome: 'Etapas e Status',
        icon: faFilter,
        link: '/configuracoes/etapas-e-status',
        color: "#ff7b00"
      },
      {
        nome: 'Temperatura',
        icon: faTemperatureHigh,
        link: '/configuracoes/temperatura',
        color: "#FF8C00"
      },
      {
        nome: 'Tipo Histórico Lead',
        icon: faClock,
        link: '/configuracoes/tipo/historico_lead',
        color: "#0000FF"
      },
      {
        nome: 'Tipo de pendências',
        icon: faAlignLeft,
        link: '/configuracoes/tipo/pendencias',
        color: "#9370DB"
      },
      {
        nome: 'Origem',
        icon: faBookmark,
        link: '/configuracoes/origem',
        color: "#297F87"
      },
      {
        nome: 'Mapeamento',
        icon: faMap,
        link: '',
        color: "#4A403A"
      },
      {
        nome: 'Configurações',
        icon: faUserTie,
        link: '/configuracoes/gestao',
        color: "#C37B89"
      },
      // {
      //   nome: 'Templates',
      //   icon: faColumns,
      //   link: '',
      //   color: "#FFEF78"
      // },
      {
        nome: 'Integrações',
        icon: faArrowsAltH,
        link: '/integracoes',
        color: "#916BBF"
      },
      {
        nome: 'Locais de Atendimento',
        icon: faLocationArrow,
        link: 'configuracoes/locais',
        color: "#0d18"
      },
      {
        nome: 'Tarefas Agendadas',
        icon: 'calendar-alt',
        link: '/configuracoes/rotinas',
        color: "#0d18"
      },
      {
        nome: 'Automações',
        icon: faRobot,
        link: '/configuracoes/automacoes',
        color: "#7E2E84"
      },
      {
        nome: 'Questionários',
        icon: faFileAlt,
        link: '/configuracoes/questionarios',
        color: "#219ebc"
      },
      {
        nome: 'Alertas',
        icon: faBell,
        link: '/configuracoes/alertas',
        color: "#219ebc"
      },     
    ]

    maisfuncionalidades.sort((a, b) => {
      let x = a.nome.toUpperCase()
      let y = b.nome.toUpperCase()
      return x.localeCompare(y)
    })

    configuracoes.sort((a, b) => {
      let x = a.nome.toUpperCase()
      let y = b.nome.toUpperCase()
      return x.localeCompare(y)
    })
    // {validarPermissaoAcessoTela('investimento_midias') && (
    //   <li>
    //     <a href="https://atendimento.dommus.com.br" target="_blank" rel="noreferrer">
    //       <FontAwesomeIcon
    //         color="#2F435A"
    //         icon={faPhoneAlt}
    //         data-tip="Central de Atendimento"
    //       />
    //       <p>Central de Atendimento</p>
    //     </a>
    //   </li>
    // )}

    setmaisFuncionalidades(maisfuncionalidades)
    setconfiguracoes(configuracoes)
  }

  function closeMenu (){
    if(isMobile){
      const sidebarMobile = document.getElementById('sidebarMobile');
      const asidebar = document.getElementById('asideMobile');
      const asidebarWrapper = document.getElementById('asideWrapperMobile');
      const barsMenuMobile = document.getElementById('barsMenuMobile');
      
      asidebar.classList.add('hide');
      asidebarWrapper.classList.add('is-closed');
      sidebarMobile.style.display = 'none';
      barsMenuMobile.src = menu;
    }
  }

  return (
    Object.keys(permissoes).length > 0 && (
      <>
        <aside className={classeAsideSidebar} id="asideMobile">
          {open ? <ReactTooltip /> : <></>}
          <div className={classeSidebarWrapper} id="asideWrapperMobile">
            {(isMobile && !isTablet) && 
              <div className="pesquisa-geral-mobile">
                <PesquisaGeralInput />
              </div>
            }
            <div className="sidebar-title">
              <span>Menu</span>
              <a
                href="#"
                data-tip="Menu"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <FontAwesomeIcon icon={faStream} />
              </a>
            </div>
            <div className="siderbar-holder">
              <div className="menu-structure">
                <ul>
                  {validarPermissaoAcessoTela('dashboards_dinamicos') && (
                    <li onClick={ () => closeMenu() }>
                      <Link to="/dashboards" target="_self">
                        <Icon icon={roundDashboard} color='#f44336' width="22px" data-tip="Dommus Insights"/>
                        <p>Dommus Insights</p>
                      </Link>
                    </li>
                  )}
                  {validarPermissaoAcessoTela('dashboards') && (
                    <li
                      onClick={()=>{closeMenu()}}
                      >
                      <Link
                        to="/dashboard"
                        target={buscaPath() ? "_blank" : "_self"}
                      >
                        <FontAwesomeIcon icon={faChartLine} color="#3f51b5" width="22px" data-tip="Dashboards"/>
                        <p>Dashboard</p>
                      </Link>
                    </li>
                  )}

                  {validarPermissaoAcessoTela('oportunidades') && (
                    <li
                      onClick={()=>{closeMenu()}}
                    >
                      <Link
                        to="/oportunidades"
                        target={buscaPath() ? "_blank" : "_self"}
                      >
                        <FontAwesomeIcon
                          color="#4caf50"
                          icon={faFunnelDollar}
                          data-tip="Lista de Oportunidades"
                        />
                        <p>Oportunidades</p>
                      </Link>
                    </li>
                  )}
                  {validarPermissaoAcessoTela('oportunidades') && (
                    <li
                      onClick={()=>{closeMenu()}}
                    >
                      <Link
                        to=""
                        onClick={() => {
                          setOpenModalNovaOportunidade(true);
                        }}
                      >
                        <FontAwesomeIcon
                          color="#8E05C2"
                          icon={faPlusCircle}
                          data-tip="Nova Oportunidade"
                        />
                        <p>Nova Oportunidade</p>
                      </Link>
                    </li>
                  )}

                  <li>
                    <a href="https://desk.blip.ai/" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon
                        color="#00c6d7"
                        icon={faComment}
                        data-tip="Atendimento Online"
                      />
                      <p>Atendimento Online</p>
                    </a>
                  </li>
                  {validarPermissaoAcessoTela('agenda_trabalho') && (
                    <li>
                      <a
                        href={guService.getLocalState("linkVendas") || "#"}
                        target="_blank" rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          color="#3f51b5"
                          icon={faCalendarMinus}
                          data-tip="Agenda de Trabalho"
                        />
                        <p>Agenda de Trabalho</p>
                      </a>
                    </li>
                  )}
                  {validarPermissaoAcessoTela('agenda_trabalho') && (
                    <li>
                      <a href="https://atendimento.dommus.com.br" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon
                          color="#2F435A"
                          icon={faPhoneAlt}
                          data-tip="Central de Atendimento"
                        />
                        <p>Central de Atendimento</p>
                      </a>
                    </li>
                  )}
                  {validarPermissaoAcessoTela('empreendimentos') && (
                    <li>
                      <Link
                        to="/portal-do-corretor"
                        target={buscaPath() ? "_blank" : "_self"}
                      >
                        <FontAwesomeIcon
                          color="#4caf50"
                          icon={faBuilding}
                          data-tip="Portal do Corretor"
                        />
                        <p>Portal do Corretor</p>
                      </Link>
                    </li>
                  )}
                  {validarPermissaoAcessoTela('visitas_recepcionista') && (
                    <li>
                      <Link
                        to="/visitas"
                        target={buscaPath() ? "_blank" : "_self"}
                      >
                        <FontAwesomeIcon
                          color="#4caf50"
                          icon={faCalendar}
                          data-tip="Visitas Agendadas"
                        />
                        <p>Visitas Agendadas</p>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className="info-structure">
                <ul>
                  <li
                    onClick={() => {
                        setOpenMaisFunc(!openMaisFunc);
                    }}
                    ref={refBtnMaisFunc}
                  >
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faTh}
                        color="#f19d1f"
                        data-tip="Mais Funcionalidades"
                      />
                      <p>Mais Funcionalidades</p>
                    </a>
                  </li>

                  <li
                    onClick={() => {
                        setOpenConfiguracoes(!openConfiguracoes);
                    }}
                    ref={refBtnConfiguracao}
                    >
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faCog}
                        color="#f7941d"
                        data-tip="Configurações"
                      />
                      <p>Configurações</p>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      setOpenAtendimento(!openAtendimento);
                    }}
                    ref={refBtnAtendimentos}
                  >
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faHeadset}
                        color="#868aa8"
                        data-tip="Atendimento"
                      />
                      <p>Atendimento</p>
                    </a>
                  </li>
                  <Logout />
                </ul>
                <div className="copy">
                  Copyright © 2020{" "}
                  <a href="https://www.linkedin.com/company/dommus-sistemas/">
                    Dommus Sistemas
                  </a>
                </div>
              </div>
            </div>
          </div>
        </aside>

        <div className="">
          {/* ATENDIMENTO */}
          <div className="menu-sections">
            <Submenu title="Atendimento ao Usuário" open={openAtendimento} callback={setOpenAtendimento} refParent={[refBtnAtendimentos]}>
              <h6>Suporte:</h6>
              <ul className="chat">
                <li>
                  <a
                    href="https://g3comunicacao.tomticket.com/?account=1390400P23042018093406"
                    target="_blank" rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTicketAlt} color="#ffcd56" />
                    <p>Ticket</p>
                  </a>
                  <span>|</span>
                  <a
                    href="https://g3comunicacao.tomticket.com/chat/?id=EP21725&amp;ac=1390400P23042018093406"
                    target="_blank" rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faCommentAlt} color="#ffcd56" />
                    <p>Chat</p>
                  </a>
                </li>
              </ul>
              <h6>Telefones:</h6>
              <ul className="telefones">
                <li>
                  <a href="tel:+55 31 38899776">
                    <FontAwesomeIcon icon={faHeadphonesAlt} color="#673AB7" />
                    <p>31 3889 - 9776</p>
                  </a>
                </li>
                <li>
                  <a href="tel:+55 31 984895755">
                    <FontAwesomeIcon icon={faWhatsapp} color="#009688" />
                    <p>31 98489 - 5755</p>
                  </a>
                </li>
              </ul>
              <h6>Email:</h6>
              <ul className="email">
                <li>
                  <a href="mailto:comercial@dommus.com.br">
                    <FontAwesomeIcon icon={faEnvelope} color="#E57373" />
                    <p>comercial@dommus.com.br</p>
                  </a>
                </li>
              </ul>
              <h6>Redes Sociais:</h6>
              <ul className="redes-sociais">
                <li>
                  <a
                    href="https://www.facebook.com/dommussistemas/"
                    target="_blank" rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} color="#3b5998" />
                    <p>Facebook</p>
                  </a>
                  <span>|</span>
                  <a
                    href="https://www.linkedin.com/company/dommus-sistemas/"
                    target="_blank" rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} color="#3b5998" />
                    <p>LinkedIn</p>
                  </a>
                </li>
              </ul>
              <h6>Site:</h6>
              <ul className="website">
                <li>
                  <a href="https://website.dommus.com.br" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faChrome} color="#f7941d" />
                    <p>Website</p>
                  </a>
                </li>
              </ul>
              <h6>V 0.0.1</h6>
            </Submenu>
            {/* Configurações */}
            <Submenu title="Configurações" open={openConfiguracoes} callback={setOpenConfiguracoes} refParent={[refBtnConfiguracao, refBtnMapeamento, refMapeamento]}>
              <ul className="chat">
                  {
                    configuracoes.map((conf, i) => (
                      conf.nome == 'Mapeamento' ?
                      <li key={i}
                        onClick={() => {
                          setOpenMapeamento(!openMapeamento);
                        }}
                        ref={refBtnMapeamento}
                      >
                        <a className="linkMapeamento" href="#">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon icon={conf.icon} color={conf.color} />
                            <p>{conf.nome}</p>
                          </div>
                          <FontAwesomeIcon icon={openMapeamento ? faArrowLeft : faArrowRight} color="#868aa8" />
                        </a>
                      </li> :
                      <li key={i}
                        onClick={() => {
                          setOpenConfiguracoes(false);
                          setOpenMapeamento(false);
                          setOpenMaisFunc(false);
                          closeMenu();
                        }}
                      >
                        <Link
                          to={conf.link}
                          target={buscaPath() ? "_blank" : "_self"}
                        >
                          <FontAwesomeIcon color={conf.color} icon={conf.icon} />
                          <p>{conf.nome}</p>
                        </Link>
                      </li>
                    ))
                  }
              </ul>
            </Submenu>
            {/* MAIS FUNCIONALIDADES */}
            <Submenu title="Mais Funcionalidades" open={openMaisFunc} callback={setOpenMaisFunc} refParent={[refBtnMaisFunc]}>
              <ul className="chat">
                {
                  maisFuncionalidades.map((func, i) => (
                    func.nome == 'Investimento em Mídias' ? (
                      validarPermissaoAcessoTela('investimento_midias') ? (
                        <li 
                          key={i}
                          onClick={() => {
                            setOpenMaisFunc(false);
                            closeMenu();
                          }}
                        >
                          <Link to={func.link}>
                            <FontAwesomeIcon color={func.color} icon={func.icon} data-tip={func.nome} className="mr-3 ml-1"/>
                            <p>{func.nome}</p>
                          </Link>
                        </li>
                      ) : <></>) :
                  func.nome == 'Gestão dos Usuários' ? (
                    validarPermissaoAcessoTela('perfis_usuarios') ? (
                      <li key={i}
                      onClick={() => {
                        setOpenMaisFunc(false);
                        closeMenu();
                      }}
                      >
                        <Link to={func.link}>
                          <FontAwesomeIcon
                            color={func.color}
                            icon={func.icon}
                            data-tip={func.nome}
                          />
                          <p>{func.nome}</p>
                        </Link>
                      </li>
                    ) : <></>) :
                    func.nome == 'Campos Qualificação' ?
                    (permissaoCamposDinamicos.includes(Number(guService.getLocalState("tipoUsuario"))) ? (
                      <li key={i}
                        onClick={() => {
                          setOpenMaisFunc(false);
                          closeMenu();
                        }}>
                        <Link to={func.link}>
                          <FontAwesomeIcon
                            color={func.color}
                            icon={func.icon}
                            data-tip={func.nome}
                          />
                          <p>{func.nome}</p>
                        </Link>
                      </li>
                    ) : <></>) :
                    func.nome == 'Régua de Nutrição' ?
                    (validarPermissaoAcessoTela('regua_nutricao') && guService.validaPermissaoModulo(guService.ID_MODULO_CENTRAL_ATENDIMENTO) ? (
                      <li key={i}
                      onClick={() => {
                        setOpenMaisFunc(false);
                        closeMenu();
                      }}
                      >
                        <Link to={func.link}>
                          <FontAwesomeIcon
                            color={func.color}
                            icon={func.icon}
                            data-tip={func.nome}
                          />
                          <p>{func.nome}</p>
                        </Link>
                      </li>
                    ) : <></>)
                    :
                    func.nome == 'Historico de Distribuição' ? (
                      [1, 2, 3, 4, 7, 8, 9, 10, 25, 26].includes(Number(guService.getLocalState("tipoUsuario"))) ? (
                        <li key={i}
                        onClick={() => {
                          setOpenMaisFunc(false);
                          closeMenu();
                        }}
                        >
                          <Link to={func.link}>
                            <FontAwesomeIcon
                              color={func.color}
                              icon={func.icon}
                              data-tip={func.nome}
                            />
                            <p>{func.nome}</p>
                          </Link>
                        </li>
                      ) : <></>) :
                    <li key={i}
                      onClick={() => {
                        setOpenMaisFunc(false);
                        closeMenu();
                      }}
                    >
                      <Link to={func.link}>
                        <FontAwesomeIcon
                          color={func.color}
                          icon={func.icon}
                          data-tip={func.nome}
                          target={buscaPath() ? "_blank" : "_self"}
                        />
                        <p>{func.nome}</p>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </Submenu>
          </div>
          <div className="menu-sections" ref={refMapeamento}>
            <Submenu title="Mapeamentos" open={openMapeamento} callback={setOpenMapeamento} refParent={[refBtnMapeamento]}>
              <ul className="chat">
                <li
                  onClick={() => {
                    setOpenConfiguracoes(false);
                    setOpenMapeamento(false);
                    setOpenMaisFunc(false);
                    closeMenu();
                  }}
                >
                  <Link
                    to="/configuracoes/mapeamento/midias"
                    target={buscaPath() ? "_blank" : "_self"}
                  >
                    <p>Mapeamento de Mídias</p>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setOpenConfiguracoes(false);
                    setOpenMapeamento(false);
                    setOpenMaisFunc(false);
                    closeMenu();
                  }}
                >
                  <Link
                    to="/configuracoes/mapeamento/origem"
                    target={buscaPath() ? "_blank" : "_self"}
                  >
                    <p>Mapeamento de Origens</p>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setOpenConfiguracoes(false);
                    setOpenMapeamento(false);
                    setOpenMaisFunc(false);
                    closeMenu();
                  }}
                >
                  <Link
                    to="/configuracoes/mapeamento/campanhas"
                    target={buscaPath() ? "_blank" : "_self"}
                  >
                    <p>Mapeamento de Campanhas</p>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setOpenConfiguracoes(false);
                    setOpenMapeamento(false);
                    setOpenMaisFunc(false);
                    closeMenu();
                  }}
                >
                  <Link
                    to="/configuracoes/mapeamento/empreendimentos"
                    target={buscaPath() ? "_blank" : "_self"}
                  >
                    <p>Mapeamento de Empreendimentos</p>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setOpenConfiguracoes(false);
                    setOpenMapeamento(false);
                    setOpenMaisFunc(false);
                    closeMenu();
                  }}
                >
                  <Link
                    to="/configuracoes/mapeamento/imovelweb"
                    target={buscaPath() ? "_blank" : "_self"}
                  >
                    <p>Mapeamento ImovelWeb</p>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setOpenConfiguracoes(false);
                    setOpenMapeamento(false);
                    setOpenMaisFunc(false);
                    closeMenu();
                  }}
                >
                  <Link
                    to="/configuracoes/mapeamento/email"
                    target={buscaPath() ? "_blank" : "_self"}
                  >
                    <p>Mapeamento Email</p>
                  </Link>
                </li>
              </ul>
            </Submenu>
          </div>
        </div>
        <ModalDommus
          open={openModalNovaOportunidade}
          close={() => setOpenModalNovaOportunidade(false)}
          children={
            <NovaOportunidade
              empreendimentos={contextoGlobalEmpreendimentos.empreendimentos}
              midias={contextoGlobalOportunidades.midias}
              origens={contextoGlobalOportunidades.origens}
              campanhas={contextoGlobalOportunidades.campanhas}
              closeModal={() => {
                setOpenModalNovaOportunidade(false);
              }}
              inserirLista={() => {
                window.location.reload();
              }}
            />
          }
          titulo="Cadastrar nova oportunidade"
        />
      </>
    ) || <></>
  );
}
