import React, {useContext, useEffect, useState} from 'react';
import ModalDommus from "../Modal";
import {format, parseISO} from "date-fns";
import { Alert, ButtonGroup, Spinner, ToggleButton } from 'react-bootstrap'
import ModalHistorico from "./ModalHistorico";
import {
    VerticalTimeline
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import './style.css';
import { ReguaNutricaoService } from '../../services/ReguaNutricaoService';
import { swalErro } from '../../helpers/DommusSwal';
import { DommusCardHistorico } from '../DommusCardHistorico';
import { DommusAlert } from '../DommusAlert';
import guService from '../../services/GuService';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HistoricoService } from '../../services/HistoricoService';
import LeadService from '../../services/LeadService';
import { trackPromise } from 'react-promise-tracker';
import oportunidadeService from '../../services/OportunidadeService';
import { DetalhesOportunidadeContext } from '../../contexts/DetalhesOportunidadeContext/DetalhesOportunidadeContext';

export default function HistoricoLead({idLead, idOportunidade}) {
    const [radioValue, setRadioValue] = useState('1');
    const [openHistoricoModal, setOpenHistoricoModal] = useState(false);
    const [dadosHistorico, setDadosHistorico] = useState('');
    const [dadosHistoricoDescricao, setDadosHistoricoDescricao] = useState('');
    const [historicoNutricao, setHistoricoNutricao] = useState([])
    const [idAtendimentoProspectaState,setIdAtendimentoProspecta] = useState();
    const [idAcaoHistorico, setIdAcaoHistorico] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [conteudos, setConteudos] = useState([])
    const [pageConteudoMensagemConecta,setPageConteudoMensagemConecta] = useState(1);
    const [isConecta,setIsConecta] = useState(false);
    const visualizarHistoricoUnificado = (guService.getLocalState('visualizar_historico_unificado') === 'true');
    const { historicos, setHistoricos, referenciaHistorico, setReferenciaHistorico } = useContext(DetalhesOportunidadeContext)


 


    const radios = [
        { name: 'Histórico', value: '1' },
        { name: 'Nutrição', value: '2' },
    ];
    const id_oportunidade = window.location.href.split('/').at(-1)

    useEffect(() => {
        if(Number(radioValue) == 2 && historicoNutricao.length === 0 && guService.validaPermissaoModulo(guService.ID_MODULO_CENTRAL_ATENDIMENTO)) {
            ReguaNutricaoService.buscarLogs(id_oportunidade)
                .then(response => setHistoricoNutricao(response.data))
                .catch(erro => swalErro(`Houve um erro ao buscar o histórico da regua de nutrição. Status: ${erro.status}, ${erro.response?.data?.message}. ${erro}`))
        }
    }, [radioValue])


    useEffect(()=>{
        if(!visualizarHistoricoUnificado && idOportunidade!=null){
            setPage(1);
            setRefresh((current)=>!current);
        }
    },[idLead])

    useEffect(()=>{
        setPage(1);
        setRefresh((current)=>!current);
    },[visualizarHistoricoUnificado])

    useEffect(()=>{
        handleCarregarHistorico();
    },[page, refresh])


    function handleMontarReferenciaHistorico(id, referencia) {
        return (referenciaHistorico != null) && (id == referenciaHistorico.id) && (referencia == referenciaHistorico.referencia) ? null : { id, referencia }
    }

    const handleCarregarHistorico = () => {
        let promise = null
        let referenciaHistoricoTemp = {}
        if(visualizarHistoricoUnificado && idOportunidade != null) {
            referenciaHistoricoTemp = handleMontarReferenciaHistorico(idOportunidade, 'oportunidade')
           
            promise = HistoricoService.getHistoricoPorOportunidade(idOportunidade, 8, page)
        } else if(idLead != 0) {
            referenciaHistoricoTemp = handleMontarReferenciaHistorico(idLead, 'lead')
            promise = LeadService.historico(idLead, 8, page)
        }
        if(!promise) {
            return
        }

        promise.then(response => {
            if(page === 1){
                setHistoricos(response.data);
            } else {
                setHistoricos(current => [...current, ...response.data]);
            }
            setHasMore(response.data.length >= 8);
            setReferenciaHistorico(referenciaHistoricoTemp)
        }).catch(error => {
            console.log(error)
            console.log(error.response)
            swalErro('Ocorreu um erro ao buscar os históricos')
        })
    }

    const handleCarregarMaisHistorico = ()=>{
        setPage((current)=>{return current + 1});
    }



    const incrementPageConteudoMensagens = () => {

        setPageConteudoMensagemConecta((prevPage) => {
            const newPage = prevPage + 1;
            return newPage;
        });
    };

    /**
     * @author Vinícius Krausche
     * @description recarrega  mais conteúdos ao scrollar as mensagens no modal de mensagens
     */

    const handleCarregarMaisConteudosAtendimentosConecta = () => {
       
        trackPromise(oportunidadeService.conteudosAtendimentoConecta(idAtendimentoProspectaState,pageConteudoMensagemConecta)).then(
            (response) => {
                const newConteudos =conteudos.concat(response.data)
                newConteudos.sort((a, b) => new Date(a.criado_em) - new Date(b.criado_em));
               setIsConecta(true)
               setConteudos(newConteudos)
               setOpenHistoricoModal(true);
            }).catch(response => {
                console.log("ERRO: "+ response)
            })
    }

    function setHistoricoDados(dados, tipo,id_atendimento_prospecta, idAcaoHistorico) {
        setDadosHistorico(dados);
        setDadosHistoricoDescricao(tipo);
        setIdAcaoHistorico(idAcaoHistorico);
        if(id_atendimento_prospecta != null){
         trackPromise(oportunidadeService.conteudosAtendimentoConecta(id_atendimento_prospecta,pageConteudoMensagemConecta)).then(
             (response) => {
                setIsConecta(true)
                setIdAtendimentoProspecta(id_atendimento_prospecta)
                setConteudos(response.data)
                setOpenHistoricoModal(true);
             }).catch(response => {
                 console.log("ERRO: "+ response)
             })
        }else{
            setConteudos([])
            setIsConecta(false);
            setPageConteudoMensagemConecta(1);
            setOpenHistoricoModal(true);

        }

    }


    useEffect(() => {
        if(pageConteudoMensagemConecta > 1){
            handleCarregarMaisConteudosAtendimentosConecta()
        }
    },[pageConteudoMensagemConecta])
    return (
        <>
            <div className="tab-content leads-content historico-lead" id="pills-tabContent">
                <div className="tab-pane fade active show" id="pills-home" role="tabpanel"
                     aria-labelledby="pills-home-tab">
                    <div className="top-group-radio-historico">
                        <ButtonGroup className="mb-2">
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant={idx % 2 ? 'outline-warning' : 'outline-warning'}
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                    size="sm"
                                >
                                {radio.name}
                            </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                    {radioValue === '1' ? historicos.length ? (
                            <VerticalTimeline layout="1-column-right" >
                                <InfiniteScroll
                                    className='tab-content'
                                    dataLength={historicos.length}
                                    next={handleCarregarMaisHistorico}
                                    hasMore={hasMore}
                                    scrollableTarget='pills-tabContent'
                                    loader={
                                        <Alert variant="info" className="alert-loading">
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                size="sm"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </Spinner>
                                            <span className="ml-3 scroll-carregando-msg">
                                                Carregando
                                            </span>
                                        </Alert>
                                    }
                                    endMessage={
                                        (hasMore && (
                                            <Alert
                                                variant="info"
                                                className="alert-loading"
                                            >
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    size="sm"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                                <span className="scroll-carregando-msg">
                                                    Carregando
                                                </span>
                                            </Alert>
                                        )) || (
                                            <Alert
                                                variant={"warning"}
                                                className="alert-section"
                                            >
                                                <strong>
                                                    Não há mais históricos a carregar!
                                                </strong>
                                            </Alert>
                                        )
                                    }
                                >
                                {historicos.map((historicoItem, index) => (
                                    <DommusCardHistorico
                                        chave={index}
                                        key={index}
                                        funcaoClique={() => setHistoricoDados(
                                          historicoItem.detalhe_historico,
                                          historicoItem.descricao,
                                          historicoItem?.id_atendimento_prospecta,
                                          historicoItem?.acao_historico?.id_acao_historico
                                        )}
                                        data={historicoItem.atualizado_em}
                                        descricao={historicoItem.descricao}
                                        detalhes={historicoItem.detalhe_historico}
                                        criado_por={historicoItem.nome_criado_por?.length ? historicoItem.nome_criado_por[0] : ''}
                                        tipoHistorico={historicoItem.id_tipo_historico}
                                        icone={'history'}
                                        cor_icone={'#f7941d'}
                                        icone_descricao={'Historico'}
                                        complemento_descricao=''
                                        id_atendimento_prospecta={historicoItem?.id_atendimento_prospecta}
                                    />
                                ))}
                                </InfiniteScroll>
                            </VerticalTimeline>
                        ) : <DommusAlert className='text-center' mensagem='Nenhum histórico a ser exibido' /> : historicoNutricao.length ? (
                            <VerticalTimeline layout="1-column-right" id>
                                {historicoNutricao.map((historico, index) => (
                                    <DommusCardHistorico
                                        key={index}
                                        chave={index}
                                        funcaoClique={() => {
                                            let detalhesHistorico = `Meio de Comunicação: ${historico.corpo_requisicao?.meio_comunicacao} \nContato: ${historico.corpo_requisicao?.dados_mensagem?.to} \nData ${historico.concluido ? 'do envio: ' : 'prevista para o envio: '}${historico.corpo_requisicao?.data_execucao ? format(parseISO(historico.corpo_requisicao?.data_execucao), "dd/MM/yyyy' às ' HH:mm") : 'Não identificado'}`
                                            setHistoricoDados(detalhesHistorico, historico.corpo_requisicao?.dados_mensagem?.subject, null)
                                        }}
                                        data={historico.atualizado_em}
                                        descricao={historico.corpo_requisicao?.dados_mensagem?.subject}
                                        criado_por={historico.nome_criado_por?.length ? historico.nome_criado_por : ''}
                                        icone={historico.concluido ? 'check' : 'calendar-alt'}
                                        cor_icone={historico.concluido ? '#25D366' : '#f7941d'}
                                        icone_descricao={historico.concluido ? 'Enviado' : 'Agendado'}
                                        tipoHistorico={historico.id_tipo_historico}
                                        complemento_descricao={historico.concluido ? (
                                           <>
                                                <strong>Enviado em: </strong>{historico.corpo_requisicao.data_execucao ? format(parseISO(historico.corpo_requisicao.data_execucao), "dd/MM/yyyy' às ' HH:mm") : 'Não identificado'}
                                           </>
                                        )  : (
                                            <>
                                                <strong>Agendado para: </strong>{historico.corpo_requisicao.data_execucao ? format(parseISO(historico.corpo_requisicao.data_execucao), "dd/MM/yyyy' às ' HH:mm") : 'Não identificado'}
                                            </>
                                        )}
                                    />
                                ))}
                            </VerticalTimeline>
                        ) : <DommusAlert className='text-center' mensagem='Nenhum histórico de nutrição a ser exibido' />
                    }
                </div>
            </div>

            <ModalDommus open={openHistoricoModal} close={setOpenHistoricoModal.bind(false)}
                         content={
                         <ModalHistorico
                         isConecta={isConecta}
                          carregarMaisConteudosConecta={incrementPageConteudoMensagens}
                           dados={dadosHistorico}
                           tipo={dadosHistoricoDescricao}
                           idAtendimentoProspecta={idAtendimentoProspectaState}
                           idAcaoHistorico={idAcaoHistorico}
                           conteudos={conteudos}
                         />}
                         titulo="Considerações de histórico" />
        </>
    );
}
