import { NivelInteresseType } from "../../../components/FormFiltroPadrao/types"
import { CanalType } from "../../../contexts/CanaisContext/types"
import { TagOportunidadeType } from "../../../contexts/TagOportunidadeContext/types"
import { StatusOportunidadeType } from "../../Configuracoes/FunilDeVendas/Funil/types"

export type TipoAtendimentoType = {
    id_tipo_atendimento: number,
    nome: string
    permitir_conclusao_no_cadastro: boolean | number
}

export type TipoTarefaType = {
    id: number,
    nome: string,
    cor: string
}

export type LocaisAtendimentoType = {
    id_local: number,
    nome: string,
    endereco: string,
    criado_em: string,
    oculto: number,
    ativo: string
}

export type OportunidadeEnvolvidoType = {
    id_envolvido: number
    id_envolvido_oportunidade: number
    id_oportunidade: number
    envolvido: EnvolvidoType
    atualizado_em: string
    criado_em: string
    ativo: string
}

export type EnvolvidoType = {
    nome: string | null
    id_envolvido: number | null
    rg: number | null
    orgao_emissor: string | null
    data_emissao: string | null
    telefone: number | null
    celular: number | null
    email: string | null
    profissao: string | null
    endereco: string | null
    numero: number | null
    data_nascimento: string | null
    genero: string | null
    complemento: string | null
    bairro: string | null
    cidade: string | null
    estado: string | null
    cep: number | null
    cpf: number | null
}

export type MidiaType = {
    id_midia: number
    descricao: string
    id_origem: number | null
    logo: string | null
    cor: string | null
    icone: string | null
    oculto: number
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
    percentual_captacoes: string | null
    total_leads: string | null
}

export type LeadType = {
    id_lead: number
    id_externo: string | null
    id_campanha: number | null
    id_midia: number | null
    nome: string | null
    email: string | null
    telefone: string | null
    id_empreendimento: number | null
    id_origem: number | null
    tags: string | null
    informacoes_adicionais: string | null
    id_importacao: number | null
    tipo: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string | null
    midia: MidiaType | null
    campanha: CampanhaType | null
    lead_oportunidades: Array<LeadOportunidadeType> | []
    origem: OrigemType | null
    nome_empreendimento: string | null
    canal: CanalType | null
    empreendimento_diferente?: boolean
}

export type OrigemType = {
    id_origem: number
    origem: string
    tipo: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
    campanhas: string
    midias: string
    oculto: string
}

export type CampanhaType = {
    id_campanha: number
    id_template: number | null
    nome: string
    objetivo: string | null
    opcoes_customizacao: string | null
    campos_questionario: string
    campos_obrigatorios: string | null
    oculto: number
    criado_em: string | null
    atualizado_em: string | null
    ativo: string | null
    campanha_midias: Array<CampanhaMidiaType> | []
    historicos_campanha: Array<HistoricoCampanhaType> | []
    palavras_chave: Array<PalavraChaveType> | []
    template: TemplateType | null
    empreendimentos: Array<CampanhaEmpreendimentoType> | []
}

export type CampanhaMidiaType = {
    id_campanha: number
    id_midia: number | null
    link: string | null
    visualizacoes: number | null
    visualizacoes_estimado: number | null
    clicks: number | null
    clicks_estimado: number | null
    tipo_custo: string
    custo_estimado: number | null
    custo_realizado: number | null
    inicio_veiculacao: string | null
    termino_veiculacao: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string | null
    campanha: CampanhaType
    midia: MidiaType
    anuncio_facebook: Array<AnuncioFacebookType> | []
    custos: CampanhaMidiaCustoType[] | null
}

export type AnuncioFacebookType = {
    id_anuncio_facebook: number
    tipo: string | null
    id_campanha: number | null
    id_midia: number | null
    id_empreendimento: number | null
    id_campanha_midia: string | null
    id_direcionamento_facebook: number | null
    id_criativo_facebook: number | null
    id_pagina: string | null
    id_instagram: string | null
    plataforma: string | null
    id_externo_campanha: number | null
    id_externo_adset: number | null
    id_externo_criativo: number | null
    id_externo_ad: number | null
    id_externo_form: number | null
    descricao_form: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
    campanha_midia: CampanhaMidiaType
    direcionamento_facebook: Array<DirecionamentoFacebookType> | []
    criativo_facebook: Array<CriativoFacebookType> | []
}

export type CriativoFacebookType = {
    id_criativo_facebook: number
    titulo: string | null
    image_hash: string | null
    caminho_imagem: string | null
    message: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
}

export type DirecionamentoFacebookType  = {
    id_direcionamento_facebook: number
    descricao: string | null
    opcoes: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
}

export type CampanhaEmpreendimentoType = {
    id_campanha: number | null
    id_empreendimento: number
    equipes_pdv: string | null
    equipes_pre_atendimento: string | null
    ativo: string | null
    criado_em: string | null
    atualizado_em: string | null
}

export type TemplateType = {
    id_template: number
    descricao: string
    componente: string | null
    opcoes_customizacao: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string | null
}

export type PalavraChaveType = {
    id_palavra_chave: number
    texto: string
    criado_em: string | null
    atualizado_em: string | null
    ativo: string | null
}

export type HistoricoCampanhaType = {
    id_historico_campanha: number
    id_tipo_historico_campanha: number
    id_campanha: number
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
}

export type CampanhaMidiaCustoType = {
    id_campanha_midia_custo: number
    id_campanha: number
    id_midia: number
    mes: number
    ano: number
    custo_estimado: number
    custo_realizado: number
    ativo: string | null
    criado_em: string | null
    atualizado_em: string | null
}

export type OportunidadeType = {
    id_oportunidade: number
    id_empreendimento: number
    id_equipe_pre_atendimento: number
    id_gerente_pre_atendimento: number
    id_usuario_pre_atendimento: number
    id_equipe_pdv: number
    id_gerente_pdv: number
    id_usuario_atendimento: number
    id_status_oportunidade: number
    id_substatus_oportunidade: number
    resgate: number
    convertido_em: string
    ocultar_dados: number
    criado_em: string
    criado_por: number
    atualizado_em: string
    ativo: string
    leads_oportunidade: Array<LeadOportunidadeType> | []
    interesse: InteresseType | null
    status: StatusOportunidadeType | null
    data_contabilizacao?: string|null
    data_venda?: string|null
    valor_venda?: string|null
    usuario_pode_editar?: boolean 
    visualizar_historicos_oportunidade_unificado?: boolean
    tags_oportunidade?: TagOportunidadeType[]
    envolvidos_oportunidade?: OportunidadeEnvolvidoType[]
}

export type InteresseType = {
    id_oportunidade_interesse: number
    caracteristicas: string | null
    localizacao: string | null
    tipologia: string | null
    empreendimento: string | null
    informacoes: string | null
    valor_disponivel: number | null
    pretensao_sinal: number | null
    renda_familia: number | null
    nivel_interesse: string | null
    id_nivel_interesse: number | null
    como_conheceu: string | null
    id_como_conheceu: string | null
    id_origem: number | null
    possui_dependentes: string | null
    tempo_previsto_compra: string | null
    saldo_fgts: number | null
    id_oportunidade: number | null
    quem_indicou: string | null
    dados_dinamicos: string | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
    comoConheceu: ComoConheceuType
    nivelInteresse: NivelInteresseType
    origem: OrigemType
}

export type AcaoHistoricoType = {
    id_acao_historico: number
    id_tipo_historico_lead: number
    nome: string
    tipos_historicos: Object | null
    criado_em: string | null
    atualizado_em: string | null
    ativo: string
}

export type HistoricoLeadType = {
    id_historico_lead: number
    id_tipo_historico: number
    id_tipo_historico_lead: number
    id_lead: number
    id_oportunidade: number
    id_referencia: number
    descricao: string | null
    detalhe_historico: string
    criado_em: string
    criado_por: number
    atualizado_em: string
    ativo: string
    nome_criado_por: string
    lead: LeadType | null   
    oportunidade: OportunidadeType | null
    acao_historico: AcaoHistoricoType | null 
}

export type LeadOportunidadeType = {
    id_lead: number
    id_oportunidade: number
    criado_em: string | null | undefined
    atualizado_em: string | null | undefined
    ativo: string
    lead: LeadType | undefined
    oportunidade: OportunidadeType | undefined
    historicos: HistoricoLeadType | undefined
}

export type ComoConheceuType = {
    id_como_conheceu: number
    descricao: string
    ativo: string
    criado_em: string
    atualizado_em: string
}

export type LogIntegracaoLeadType = {
    id_log_integracao: number;
    valor: string;
    id_empreendimento?: number;
    id_lead?: number;
    lead?: LeadType;
    oportunidade?: number;
    erros_integracao: logIntegracaoErrosType[];
    origem_lead: string;
    ativo: string;
    criado_em: string;
}

export type logIntegracaoErrosType = {
    id_log_integracao_erro: number;
    id_log_integracao: number;
    erro: string;
    criado_em: string;    
}

export enum TipoCampoDinamicoAPI  {
    EMPREENDIMENTO,
    TIPOLOGIA,
    LOCALIZACAO,
    COMO_CONHECEU,
}
