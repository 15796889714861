import React, { useContext, useEffect, useMemo } from "react";
import "./index.css"
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

import Select from "react-select";
import { MultiSelect, Option } from "react-multi-select-component";
import { CamposDinamicosContext } from "../../../../../../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import { EmpreendimentoContext } from "../../../../../../../components/GlobalContext/globalContext";
import { PerguntaType, TipoRespostaEnum } from "../../../../../../../contexts/PerguntaContext/types";
import { celularMask, cepMask, cpfMask, realMoeda } from "../../../../../../../components/InputTextMask";
import { TipoCampoDinamicoAPI } from "../../../../../Cadastro/types.d";
import { QuestionarioPublicoType } from "../../../../../../../contexts/QuestionarioContext/types";

interface PerguntaItemProps {
  questionarioPublico: QuestionarioPublicoType;
  pergunta: PerguntaType;
  index: number;
}

export function PerguntaItemQuestionarioPublicoForm({
  questionarioPublico, pergunta, index
}: PerguntaItemProps) {
  const methods = useFormContext();
  const {listaComoConheceu, listaTipologia, listaLocalidades} = useContext(CamposDinamicosContext);
  const {empreendimentos} = useContext(EmpreendimentoContext)

  const configs = useMemo(() => {
    return {
      selectSomeItems: "Selecione",
      allItemsAreSelected: "Todos itens selecionados",
      selectAll: "Selecionar todos",
      search: "Pesquisar",
    };
  }, []);

  useEffect(()=>{
    if(questionarioPublico?.respostas_com_perguntas?.length){
      questionarioPublico?.respostas_com_perguntas.forEach((resposta)=>{
        methods.setValue(`p-${resposta?.id_pergunta}`, resposta?.resposta ?? []);
      })
    }
  },[questionarioPublico?.respostas_com_perguntas])

  const nameCampo = useMemo(() => {
    return 'p-'+pergunta.id_pergunta.toString();
  }, [pergunta]);

  const createOptionsCamposDinamicos = (tipo: number) => {
    switch (tipo) {
      case TipoCampoDinamicoAPI.COMO_CONHECEU:
        return listaComoConheceu.map((comoConheceu) => {
          return {
            label: comoConheceu.descricao,
            value: comoConheceu.id_como_conheceu,
          };
        });
      case TipoCampoDinamicoAPI.LOCALIZACAO:
        return listaLocalidades.map((localidade, index) => {
          return {
            label: localidade,
            value: localidade,
          };
        });
      case TipoCampoDinamicoAPI.TIPOLOGIA:
        return listaTipologia.map((tipologia, index) => {
          return {
            label: tipologia.descricao,
            value: tipologia.id,
          };
        });
      case TipoCampoDinamicoAPI.EMPREENDIMENTO:
        return empreendimentos.map((empreendimento, index) => {
          return {
            label: empreendimento.nome,
            value: empreendimento.id,
          };
        });
      default:
        return [];
    }
  };

  const resolverCampo = () => {
    var options: any[] = [];
    let obrigatorio = !!Number(pergunta?.obrigatorio);

    switch (pergunta?.id_tipo_resposta_pergunta) {
      case TipoRespostaEnum.CEP:
        return (
          <Form.Control
            type="text"
            ref={methods.register({required: obrigatorio})}
            name={nameCampo}
            placeholder="Digite o CEP..."
            onChange={(event) => {
              methods.setValue(nameCampo, cepMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.CPF:
        return (
          <Form.Control
            type="text"
            ref={methods.register({required: obrigatorio})}
            name={nameCampo}
            placeholder="Digite o CPF..."
            onChange={(event) => {
              methods.setValue(nameCampo, cpfMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.TELEFONE:
        return (
          <Form.Control
            type="text"
            ref={methods.register({required: obrigatorio})}
            name={nameCampo}
            placeholder="Digite o telefone..."
            onChange={(event) => {
              methods.setValue(nameCampo, celularMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.TEXTO:
        return (
          <Form.Control
            type="text"
            ref={methods.register({required: obrigatorio})}
            name={nameCampo}
            placeholder="Digite ..."
          />
        );
      case TipoRespostaEnum.DATA:
        return (
          <Form.Control
            type="date"
            ref={methods.register({required: obrigatorio})}
            name={nameCampo}
          />
        );
      case TipoRespostaEnum.MOEDA:
        return (
          <Form.Control
            type="text"
            ref={methods.register({required: obrigatorio})}
            name={nameCampo}
            placeholder="R$ 0,00"
            onChange={(event) => {
              methods.setValue(nameCampo, `R$ ${realMoeda(event.target.value)}`);
            }}
          />
        );
      case TipoRespostaEnum.SIMPLES_SELECAO:
      case TipoRespostaEnum.SIMPLES_SELECAO_CAMPOS_DINAMICOS:
        if (Array.isArray(pergunta?.options)) {
          options = pergunta?.options;
        } else {
          options = createOptionsCamposDinamicos(
            parseInt(pergunta?.options || "0")
          ) as any[];
        }
        return (
          <Controller
            control={methods.control}
            name={nameCampo}
            defaultValue={[]}
            rules={{required: obrigatorio}}
            render={({ onChange, value=[] }) => (
              <Select
                placeholder="Selecione"
                options={(options as Option[]) || []}
                className="basic-multi-select"
                onChange={onChange}
                value={value}
              />
            )}
          />
        );
      case TipoRespostaEnum.MULTIPLA_SELECAO:
      case TipoRespostaEnum.MULTIPLA_SELECAO_CAMPOS_DINAMICOS:
        if (Array.isArray(pergunta?.options)) {
          options = pergunta?.options;
        } else {
          options = createOptionsCamposDinamicos(
            parseInt(pergunta?.options || "0")
          ) as any[];
        }
        return (
          <Controller
            control={methods.control}
            name={nameCampo}
            defaultValue={[]}
            rules={{required: obrigatorio}}
            render={({ onChange, value = [] }) => (
              <MultiSelect
                options={(options as Option[]) ?? []}
                labelledBy=""
                value={value}
                overrideStrings={configs}
                onChange={onChange}
              />
            )}
          />
        );
      case TipoRespostaEnum.CHECKBOX:
        return (
          <div className="resposta-checkbox-section">
            {pergunta?.options?.map(
              (item: any, index: number) => (
                <div key={index}>
                  <input
                    ref={methods?.register({required: obrigatorio})}
                    name={nameCampo}
                    id={`${questionarioPublico?.id_questionario_publico}-${nameCampo}-${index}`}
                    value={item.value}
                    type="checkbox"
                  />
                  <label
                    style={{ cursor: "pointer", marginBottom: "0px" }}
                    htmlFor={`${questionarioPublico?.id_questionario_publico}-${nameCampo}-${index}`}
                  >
                    {item.value}
                  </label>
                </div>
              )
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="pergunta-opv-item">
      <div className="enunciado-section">
        <span className="number">
          {`${(index + 1).toString().padStart(2, '0')}. `}
        </span>
        <span className="enunciado">
          {pergunta?.enunciado}
          {!!Number(pergunta?.obrigatorio) && 
            <span className="enunciado-obrigatorio">*</span>
          }
        </span>
      </div>
      {resolverCampo()}
      {methods.formState.errors[nameCampo] && methods.formState.errors[nameCampo] && (
        <small className="form-text text-danger">Campo Obrigatório*</small>
      )}
    </div>
  );
}
