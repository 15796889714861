import React, { useCallback, useEffect, useState, useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../NovoCadastro/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import OportunidadeService from "../../../../services/OportunidadeService";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import utilsService from "../../../../services/utils";
import DommusToggle from "../../../../components/DommusToggle";
import LeadService from "../../../../services/LeadService";
import Alert from "react-bootstrap/Alert";
import Swal from "sweetalert2";
import {
  cpfMask,
  celularMask,
  cepMask,
  apenasNumeros,
  validaCPF,
} from "../../../../components/InputTextMask";
import { trackPromise } from "react-promise-tracker";
import formatDommus from "../../../../helpers/format";
import DommusLoading from "../../../../components/DommusLoading/DommusLoading";
import { EmpreendimentoContext, OportunidadeContext } from "../../../../components/GlobalContext/globalContext";
import { EnvolvidoType } from "../types";
import useTelefoneInternacionalInput from "../../../../hooks/useTelefoneInternacionalInput";
import stringHelper from "../../../../helpers/StringHelper";
import { toastErro } from "../../../../helpers/DommusSwal";
import guService from "../../../../services/GuService";

interface NovoCadastroProps {
  envolvido: EnvolvidoType
  atualizarLista: (val: boolean) => void
  id_oportunidade: number
  envolvidos: Array<EnvolvidoType>
  closeModal: () => void
}

export default function NovoCadastro({ envolvido, atualizarLista, id_oportunidade, envolvidos, closeModal }: NovoCadastroProps) {
  const [nome, setNome] = useState(envolvido.nome || "");
  const [id_envolvido, setIdEnvolvido] = useState(envolvido.id_envolvido);
  const [cpf, setCpf] = useState("");
  const [profissao, setProfissao] = useState(envolvido?.profissao || "");
  const [rg, setRg] = useState(envolvido.rg || "");
  const [expedidor, setExpedidor] = useState(envolvido.orgao_emissor || "");
  const [dataEmissao, setDataEmissao] = useState(envolvido.data_emissao || "");
  const [telefone, setTelefone] = useState(celularMask(envolvido.telefone) || "");
  const [celular, setCelular] = useState(celularMask(envolvido.celular) || "");
  const [email, setEmail] = useState(envolvido.email || "");
  const [endereco, setEndereco] = useState(envolvido.endereco || "");
  const [numero, setNumero] = useState(envolvido.numero || "");
  const [nascimento, setNascimento] = useState(envolvido.data_nascimento || "");
  const [idade, setIdade] = useState("");
  const [travaCampo, setTravaCampo] = useState(false);
  const [campoTravado, setCampoTravado] = useState("");
  const [idProcesso, setIdProcesso] = useState("");
  const [idLead, setIdLead] = useState("");
  const [idOportunidade, setIdOportunidade] = useState("");
  const [genero, setGenero] = useState(envolvido.genero || "");
  const [bairro, setBairro] = useState(envolvido.bairro || "");
  const [cidade, setCidade] = useState(envolvido.cidade || "");
  const [estado, setEstado] = useState(envolvido.estado || "");
  const [cpfValido, setCpfValido] = useState(false);
  const [cep, setCep] = useState(cepMask(envolvido.cep) || "");
  const [complemento, setComplemento] = useState(envolvido?.complemento || "");
  const [loading, setLoading] = useState(false);
  
  const { atualizarOportunidade } = useContext(OportunidadeContext);
  const [refInputTelefone, objetoInputTelefone, getNumeroInternacional] = useTelefoneInternacionalInput();
  const [refInputCelular, objetoInputCelular, getNumeroCelularInternacional] = useTelefoneInternacionalInput();
  const global = useContext(EmpreendimentoContext);  


  useEffect(() => {
    if (envolvido.cpf) {
      handleSetCpf(envolvido.cpf);
    }
  }, [envolvido, envolvidos]);

  async function validaDadosEnvolvido(envolvido: any, id_envolvido_ignorar: number | null) {
    setLoading(true);
    const response = await LeadService.validaEnvolvido(
      envolvido,
      id_envolvido_ignorar
    );
    setLoading(false);
    if (response.data.envolvidos.length > 0) {
      return {
        op_ids: response.data.op_ids,
        valido: false,
      };
    }
    global.setLeadCriado(true);
    return { valido: true };
  }

  function buscaCep(cep: string) {
    if (cep.length >= 7) {
      utilsService.buscarPorCep(cep).then(
        (response) => {
          const dados = response.data;
          setBairro(dados.bairro);
          setCidade(dados.localidade);
          setEndereco(dados.logradouro);
          setEstado(dados.uf);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar o cep: " + error,
            icon: "error",
          });
        }
      );
    }
  }

  async function validaCadastro() {
    let valido = true;

    if (!email && !celular) {
      await Swal.fire({
        titleText: "Atenção",
        text: "Preencha o e-mail ou celular para continuar",
        icon: "warning",
      });
      return false;
    }

    //valida se o CPF é válido
    if (cpf && !cpfValido) {
      const validaCPF = await Swal.fire({
        titleText: "O CPF digitado é inválido",
        text: "Tem certeza que deseja continuar mesmo assim?",
        icon: "question",
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Sim!",
        cancelButtonText: "Não",
        customClass: {
          confirmButton: "btn btn-primary mr-3",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      if (!validaCPF.value) {
        valido = false;
      }
    }

    //não permitir envolvidos duplicados (nome / cpf)
    let envolvidosMatchObrigatorio = envolvidos.filter(function (item: any) {
      //verifica se é o envolvido sendo editado
      if (item.id_envolvido == envolvido.id_envolvido) {
        return false;
      }

      //verifica se há algum com mesmo nome ou cpf desde que esteja preenchido
      if ((!cpf || item.cpf != cpf) && (!nome || item.nome != nome)) {
        return false;
      }

      return true;
    });

    //verifica se há alguma outa OP com os dados informados

    const camposValidarEnvolvido = {
      email,
      cpf,
      telefone,
      celular,
    };
    const { op_ids, valido: envolvido_valido } = await validaDadosEnvolvido(
      camposValidarEnvolvido,
      envolvido.id_envolvido
    );
    if (!envolvido_valido) {
      const ops_count = op_ids.length;
      const ops_code = op_ids.map((op: number) => "OP-" + formatDommus.zeroPad(op, 5));

      const confirmaEnvolvidoOpDiferente = await Swal.fire({
        titleText: "Cadastro Duplicado",
        text: `Já existe ${ops_count} oportunidade com os dados do envolvido preenchido, tem certeza que deseja continuar (${ops_code})?`,
        icon: "question",
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Sim, Cadastrar!",
        cancelButtonText: "Não",
        customClass: {
          confirmButton: "btn btn-primary mr-3",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      if (!confirmaEnvolvidoOpDiferente.value) {
        return false;
      }
    }

    //verifica se há algum envolvido com nome/cpf digitados e se não é o primeiro da lista
    if (
      envolvidosMatchObrigatorio.length > 0 &&
      envolvidos[0].id_envolvido != envolvido.id_envolvido
    ) {
      await Swal.fire({
        titleText: "Atenção",
        text: "Já existe um envolvido cadastrado com o nome e/ou CPF informados",
        icon: "warning",
      });
      return false;
    }

    let envolvidosMatchAviso = envolvidos.filter(function (item) {
      //verifica se é o envolvido sendo editado
      if (item.id_envolvido == envolvido.id_envolvido) {
        return false;
      }

      //verifica se há algum com mesmo nome ou cpf desde que esteja preenchido
      if (
        (!telefone || item.telefone != telefone) &&
        (!email || item.email != email) &&
        (!celular || item.celular != celular)
      ) {
        return false;
      }

      return true;
    });
    //pedir confirmação para cadastrar envolvido com email, telefone, telefone fixo duplicado e se não é o primeiro da lista
    if (
      envolvidosMatchAviso.length > 0 &&
      envolvidos[0].id_envolvido != envolvido.id_envolvido
    ) {
      const validaCPFUnico = await Swal.fire({
        titleText: "Cadastro Duplicado",
        text: "Já existe um envolvido cadastrado com esse Telefone, Celular ou E-mail! Tem certeza que deseja prosseguir com o cadastro",
        icon: "question",
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Sim, Cadastrar!",
        cancelButtonText: "Não",
        customClass: {
          confirmButton: "btn btn-primary mr-3",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      if (!validaCPFUnico.value) {
        valido = false;
      }
    }
    return valido;
  }

  async function salvarOportunidade() {
    const valido = await validaCadastro();
    if (!valido) {
      return;
    }
    // alert('cadastrou');return;
    const dadosEnvolvido = {
      id_envolvido: id_envolvido,
      nome: nome,
      orgao_emissor: expedidor,
      numero: numero,
      data_emissao: dataEmissao,
      cpf: apenasNumeros(cpf),
      profissao: profissao,
      rg: rg,
      telefone: apenasNumeros(telefone),
      celular: apenasNumeros(celular),
      email: email,
      endereco: endereco,
      data_nascimento: nascimento,
      genero,
      bairro: bairro,
      cidade: cidade,
      estado: estado,
      complemento: complemento,
      cep: apenasNumeros(cep),
    };
    trackPromise(
      OportunidadeService.salvarEnvolvido(dadosEnvolvido, id_oportunidade).then(
        (response: any) => {
          Swal.fire({
            titleText: "Cadastrado!",
            text: "Envolvido salvo com sucesso.",
            icon: "success",
          }).then(() => {
            if (!(dadosEnvolvido.id_envolvido || false)) {
              global.setLeadCriado(true);
              atualizarOportunidade(id_oportunidade);
              closeModal();
            }
            atualizarLista(true);
            global.setLeadCriado(true);
          });
        },
        (error: any) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar o envolvido: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  const handleSetCpf = useCallback((cpf: any) => {
    setCpf(cpf);
    if (cpf.length == 14 && !validaCPF(cpf)) {
      Swal.fire({
        titleText: "Atenção",
        text: "O CPF digitado é Inválido",
        icon: "warning",
      });
      setCpfValido(false);
      setCpf("");
      return;
    }
    setCpfValido(true);
  }, []);

  useEffect(() => {
    if (!nascimento || nascimento === '0000-00-00') {
      setIdade("");
      return;
    }
    calculaIdade(nascimento);
  }, [nascimento]);

  const calculaIdade = useCallback((date: string) => {
    const final_date = new Date(Date.now() - new Date(date).getTime());
    setIdade(
      `${final_date.getUTCFullYear() - 1970} anos e ${
        final_date.getMonth() + 1
      } meses`
    );
  }, []);

  function buscaDados(campo: string) {
    let payload = {
      cpf: cpf && cpf.split(".").join("").split("-").join("") || "",
      email: stringHelper.IsEmail(email) ? email : "",
      telefone: celular || telefone,
      idOportunidadeDesconsiderar: id_oportunidade
    }
    if(campo == 'CELULAR' && objetoInputCelular && getNumeroCelularInternacional) {
      const numeroCelular = getNumeroCelularInternacional() as any
      setCelular(numeroCelular)
      payload.telefone = numeroCelular
    } else if(campo == 'TELEFONE' && objetoInputTelefone && getNumeroInternacional) {
      const numeroTelefone = getNumeroInternacional() as any
      setTelefone(numeroTelefone)
      payload.telefone = numeroTelefone
    }
    trackPromise(LeadService.buscarEnvolvidos(payload, campo,'E'))
      .then((response: any) => {
        if (response.data.status) {
          setTravaCampo(true);
          setIdProcesso(response.data.id);
          setIdOportunidade(response.data.id_oportunidade);
          setIdLead(response.data.id_lead);
          if(response.data.campo == 'TELEFONE' && ['TELEFONE', 'CELULAR'].includes(campo)) {
            setCampoTravado(campo);
          } else {
            setCampoTravado(response.data.campo)
          }
        } else {
          setCampoTravado("");
          setTravaCampo(false);
        }
      }).catch(error => {
        console.log(error)
        console.log(error?.response)
        toastErro('Houve um erro ao buscar pelos envolvidos.')
      })
  }

  function montarLinkAcessoProcessoOpvLead() {
    if(idProcesso) {
      return `${guService.getLocalState('linkVendas')}index_ui.php?mgr=MQ==&ui=NjM=&id_processo=${idProcesso}`
    }
    if(idOportunidade) {
      return `https://leads.dommus.com.br/oportunidade/${idOportunidade}`
    }
    return `https://leads.dommus.com.br/leads/represados`
  }

  return (
    <>
      {loading && <DommusLoading />}
      <Form className="formCadastro">
        <DommusToggle title="Dados do Envolvido" toggle={true}>
          <Row>
            <Form.Group as={Col} md="4" controlId="formNome">
              <Form.Label>
                Nome: <DommusRequiredSpan />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome"
                value={nome}
                onChange={(event: any) => setNome(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Gênero:</Form.Label>
              <Form.Control
                as="select"
                custom
                value={genero}
                onChange={(event) => setGenero(event.target.value)}
              >
                <option value="">Selecione o gênero</option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formNome">
              <Form.Label>CPF: </Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o CPF"
                value={cpfMask(cpf)}
                onChange={(event: any) => handleSetCpf(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formNome">
              <Form.Label>
                Profissão: 
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite a profissão"
                value={profissao}
                onChange={(event: any) => setProfissao(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>RG:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o RG"
                value={rg}
                onChange={(event: any) => setRg(event.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Orgão emissor:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ex: SSP/MG"
                value={expedidor}
                onChange={(event: any) => setExpedidor(event.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Data emissão:</Form.Label>
              <Form.Control
                type="date"
                placeholder="DD/MM/YYYY"
                value={dataEmissao}
                onChange={(event: any) => setDataEmissao(event.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Data nascimento:</Form.Label>
              <Form.Control
                type="date"
                placeholder="Digite a data de nascimento"
                value={nascimento}
                onChange={(event: any) => setNascimento(event.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Idade:</Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Idade"
                value={idade ? idade : 'Não Informado'}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="5" controlId="formNome">
              <Form.Label>
                E-mail: <DommusRequiredSpan />
              </Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder="Digite o e-mail"
                onChange={(event: any) => setEmail(event.target.value)}
                onBlur={() => buscaDados('EMAIL')}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Telefone: </Form.Label>
              <Form.Control type="text" ref={(refInputTelefone)} value={telefone} onChange={(e: any) => {setTelefone(celularMask(e.target.value))}} onBlur={() => buscaDados("TELEFONE")}/>
            </Form.Group>
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>
                Celular: <DommusRequiredSpan />
              </Form.Label>
              <Form.Control type="text" ref={(refInputCelular)} value={celular} onChange={(e: any) => {setCelular(celularMask(e.target.value))}} onBlur={() => buscaDados("CELULAR")}/>
            </Form.Group>
          </Row>
          {travaCampo && (
            <Alert variant="warning">
              <FontAwesomeIcon icon="exclamation-triangle" className="mr-1"/> Já existe {idProcesso && "um processo cadastrado" || 
                idOportunidade && "uma oportunidade cadastrada" || 
                idLead && "um lead cadastrado" || 
              ''} utilizando o <strong>{campoTravado}</strong> digitado.<a href={montarLinkAcessoProcessoOpvLead()} target="_blank" rel="noreferrer">Clique aqui para visualizar.</a>
            </Alert>
          ) || <></>}
        </DommusToggle>
        <DommusToggle title="Endereço" toggle={true}>
          <Row>
            <Form.Group as={Col} md={2} controlId="formNome">
              <Form.Label>CEP:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o cep"
                value={cepMask(cep)}
                onBlur={(event: any) => buscaCep(event.target.value)}
                onChange={(event: any) => setCep(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="7" controlId="formNome">
              <Form.Label>Endereço:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o endereço"
                value={endereco}
                onChange={(event: any) => setEndereco(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="1" controlId="formNome">
              <Form.Label>Número:</Form.Label>
              <Form.Control
                type="text"
                placeholder="N°"
                value={numero}
                onChange={(event: any) => setNumero(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="formNome">
              <Form.Label>Complemento:</Form.Label>
              <Form.Control
                type="text"
                placeholder="N°"
                value={complemento}
                onChange={(event: any) => setComplemento(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="lastRow">
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Bairro:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o bairro"
                value={bairro}
                onChange={(event: any) => setBairro(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Cidade:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite a cidade"
                value={cidade}
                onChange={(event: any) => setCidade(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Estado:</Form.Label>
              <Form.Control
                as="select"
                custom
                value={estado}
                onChange={(event) => setEstado(event.target.value)}
              >
                <option value="">Selecione o estado</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
                <option value="EX">Estrangeiro</option>
              </Form.Control>
            </Form.Group>
          </Row>
        </DommusToggle>

        <Row className="formFooter">
          <Col>
            <button
              type="button"
              onClick={() => salvarOportunidade()}
              className="btn btn-success"
              disabled={travaCampo}
              style={{ cursor: travaCampo && 'not-allowed' || 'pointer' }}
            >
              <FontAwesomeIcon icon={faSave} style={{ marginRight: "15px" }} />
              Salvar
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
