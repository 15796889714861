import React, { createContext, useState } from "react";
import { FiltroGlobalType } from "./types";
import { Option } from "react-multi-select-component";
import { toastInfo } from "../../helpers/DommusSwal";
import guService from "../../services/GuService";

interface FiltroGlobalProps {
  children: JSX.Element
}

export const FiltroGlobalContext = createContext({} as FiltroGlobalType);

export function FiltroGlobalProvider({ children }: FiltroGlobalProps) {
  //Filtro de oportunidades
  const [campanhaSelecionada, setCampanhaSelecionada] = useState<Option[]>([]);
  const [midiaSelecionada, setMidiaSelecionada] = useState<Option[]>([]);
  const [origemSelecionada, setorigemSelecionada] = useState<Option[]>([]);
  const [tipoHistoricoSelecionado,setTipoHistoricoSeleciondado] = useState<Option[]>([]);
  const [nomeLead, setNomeLead] = useState('');
  const [cpfEnvolvido, setCpfEnvolvido] = useState("");
  const [emailLead, setEmailLead] = useState("");
  const [telefoneLead, setTelefoneLead] = useState("");
  const [empreendimentoSelecionado, setEmpreendimentoSelecionado] = useState([] as Option[]);
  const [equipePreAtendimentoSelecionada, setEquipePreAtendimentoSelecionada] = useState<Option[]>([]);
  const [gerentePreAtendimentoSelecionado, setGerentePreAtendimentoSelecionado] = useState<Option[]>([]);
  const [usuarioPreAtendimentoSelecionado, setUsuarioPreAtendimentoSelecionado] = useState<Option[]>([]);
  const [equipePdvSelecionada, setEquipePdvSelecionada] = useState<Option[]>([]);
  const [gerentePdvSelecionado, setGerentePdvSelecionado] = useState<Option[]>([]);
  const [usuarioPdvSelecionado, setUsuarioPdvSelecionado] = useState<Option[]>([]);
  const [idOportunidade, setIdOportunidade] = useState("");
  const [tempoSemAtualizacao, setTempoSemAtualizacao] = useState("");
  const [dataCadastroOportunidadeInicial, setDataCadastroOportunidadeInicial] = useState("");
  const [dataCadastroOportunidadeFinal, setDataCadastroOportunidadeFinal] = useState("");
  const [statusOportunidadeSelecionada, setStatusOportunidadeSelecionada] = useState<Option[]>([]);
  const [motivoDesistencia, setMotivoDesistencia] = useState<Option[]>([]);
  const [faixaValorInicial, setFaixaValorInicial] = useState("");
  const [faixaValorFinal, setFaixaValorFinal] = useState("");
  const [interesses, setInteresses] = useState<Option[]>([]);
  const [dadosEnvolvidoFiltro, setDadosEnvolvidoFiltro] = useState(1);
  const [modificador1, setModificador1] = useState(1);
  const [filtroFaixa, setFiltroFaixa] = useState(1);
  const [tabKey, setTabKey] = useState("oportunidades");
  const [optionsSubstatus, setOptionsSubstatus] = useState<Option[]>([])
  const [substatusSelecionado, setSubstatusSelecionado] = useState<Option[]>([])
  const [dataVendaInicial, setDataVendaInicial] = useState("");
  const [dataVendaFinal, setDataVendaFinal] = useState("");
  const [dataContabilizacaoInicial, setDataContabilizacaoInicial] = useState("");
  const [dataContabilizacaoFinal, setDataContabilizacaoFinal] = useState("");
  const [agruparPorLeads, setAgruparPorLeads] = useState(false)
  const [filtrosQualificacao, setFiltrosQualificacao] = useState(null as any)
  const [tagsSelecionadas, setTagsSelecionadas] = useState([] as Option[]);
  const [canaisSelecionados, setCanaisSelecionados] = useState([] as Option[]);
  const [modificadorCampoEmpreendimento, setModificadorCampoEmpreendimento] = useState(1);
  const [aplicarFiltrosLead, setAplicarFiltrosLead] = useState(false)
  const [oportunidadesEncontradas, setOportunidadesEncontradas] = useState<any[]>([]);
  const [mostrarFiltroGlobal, setMostrarFiltroGlobal] = useState(false);
  const [mostrarListaGlobal, setMostrarListaGlobal] = useState(false);
  const [totalFiltroGlobal, setTotalFiltroGlobal] = useState(0);
  const [filtrosGlobal, setFiltrosGlobal] = useState<any[]>([]);


  //Filtro de Atendimentos
  const [optionsTiposAtendimentos, setOptionsTiposAtendimentos] = useState<JSX.Element[]>([])
  const [tipoAtendimento, setTipoAtendimento] = useState("")
  const [telefoneAtendimento, setTelefoneAtendimento] = useState("")
  const [emailAtendimento, setEmailAtendimento] = useState("")
  const [criadoPorAtendimento, setCriadoPorAtendimento] = useState([] as Array<Option>)
  const [concluidodoPorAtendimento, setConcluidoPorAtendimento] = useState([] as Array<Option>)
  const [tarefaRealizada, setTarefaRealizada] = useState("")
  const [inicioCriacaoAtendimento, setInicioCriacaoAtendimento] = useState('')
  const [fimCriacaoAtendimento, setFimCriacaoAtendimento] = useState('')
  const [inicioAtualizacaoAtendimento, setInicioAtualizacaoAtendimento] = useState('')
  const [fimAtualizacaoAtendimento, setFimAtualizacaoAtendimento] = useState('')
  const [inicioConclusaoAtendimento, setInicioConclusaoAtendimento] = useState('')
  const [fimConclusaoAtendimento, setFimConclusaoAtendimento] = useState('')
  const [inicioAgendamentoAtendimento, setInicioAgendamentoAtendimento] = useState('')
  const [fimAgendamentoAtendimento, setFimAgendamentoAtendimento] = useState('')
  const [exportar, setExportar] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [atendimentos, setAtendimentos] = useState<any[]>([])
  const [limiteAtendimentos, setLimiteAtendimentos] = useState(8)
  const [paginaAtendimentos, setPaginaAtendimentos] = useState(1)
  const [totalAtendimentos, setTotalAtendimentos] = useState(0)
  const [filtroPeriodo, setFiltroPeriodo] = useState(1)
  const [tipoFiltroUsuario, setTipoFiltroUsuario] = useState(0)
  const [campanhaAtendimento, setCampanhaAtendimento] = useState<Option[]>([])
  const [motivoDesistenciaAtendimento, setMotivoDesistenciaAtendimento] = useState<Option[]>([])
  const [empreendimentoAtendimento, setEmpreendimentoAtendimento] = useState([] as Option[])
  const [equipePdvSelecionadaAtendimento, setEquipePdvSelecionadaAtendimento] = useState<Option[]>([])
  const [gerentePdvSelecionadoAtendimento, setGerentePdvSelecionadoAtendimento] = useState<Option[]>([])
  const [usuarioPdvSelecionadoAtendimento, setUsuarioPdvSelecionadoAtendimento] = useState<Option[]>([])
  const [equipePreAtendimentoSelecionadaAtendimento, setEquipePreAtendimentoSelecionadaAtendimento] = useState<Option[]>([])
  const [gerentePreAtendimentoSelecionadoAtendimento, setGerentePreAtendimentoSelecionadoAtendimento] = useState<Option[]>([])
  const [usuarioPreAtendimentoSelecionadoAtendimento, setUsuarioPreAtendimentoSelecionadoAtendimento] = useState<Option[]>([])
  const [midiaAtendimento, setMidiaAtendimento] = useState<Option[]>([])
  const [statusOportunidadeAtendimento, setStatusOportunidadeAtendimento] = useState<Option[]>([])
  const [substatusAtendimento, setSubstatusAtendimento] = useState<Option[]>([])
  const [responsavelAtendimento, setResponsavelAtendimento] = useState([] as Array<Option>)
  const [filtroContatoAtendimento, setFiltroContatoAtendimento] = useState(true)
  const [optionsSubstatusAtendimento, setOptionsSubstatusAtendimento] = useState<Option[]>([])
  const [idTipoTarefa, setIdTipoTarefa] = useState("");
  const [listaTarefas, setListaTarefas] = useState<JSX.Element[]>([]);
  const configExibirCanalOPV = JSON.parse(guService.getLocalState('exibirCanalOpv') || 'false')

  function limparFiltros(filtro: string) {
    switch(filtro) {
      case 'oportunidades':
        limparFiltrosOportunidade()
        break;
      case 'atendimentos':
        limparFiltrosAtendimentos()
        break;
    }
    toastInfo('Filtros removidos com sucesso.')
  }

  function limparFiltrosOportunidade() {
    setCampanhaSelecionada([]);
    setMidiaSelecionada([]);
    setorigemSelecionada([]);
    setTipoHistoricoSeleciondado([]);
    setNomeLead('');
    setCpfEnvolvido("");
    setEmailLead("");
    setTelefoneLead("");
    setEmpreendimentoSelecionado([]);
    setEquipePreAtendimentoSelecionada([]);
    setGerentePreAtendimentoSelecionado([]);
    setUsuarioPreAtendimentoSelecionado([]);
    setEquipePdvSelecionada([]);
    setGerentePdvSelecionado([]);
    setUsuarioPdvSelecionado([]);
    setIdOportunidade("");
    setTempoSemAtualizacao("");
    setDataCadastroOportunidadeInicial("");
    setDataCadastroOportunidadeFinal("");
    setStatusOportunidadeSelecionada([]);
    setMotivoDesistencia([]);
    setFaixaValorInicial("");
    setFaixaValorFinal("");
    setInteresses([]);
    setDadosEnvolvidoFiltro(1);
    setModificador1(1);
    setFiltroFaixa(1);
    setTabKey("oportunidades");
    setOptionsSubstatus([])
    setSubstatusSelecionado([])
    setDataVendaInicial("");
    setDataVendaFinal("");
    setDataContabilizacaoInicial("");
    setDataContabilizacaoFinal("");
    setAgruparPorLeads(false)
    setFiltrosQualificacao(null)
  }

  function limparFiltrosAtendimentos() {
    setTipoAtendimento("")
    setTelefoneAtendimento("")
    setEmailAtendimento("")
    setCriadoPorAtendimento([])
    setConcluidoPorAtendimento([])
    setTarefaRealizada("")
    setInicioCriacaoAtendimento('')
    setFimCriacaoAtendimento('')
    setInicioAtualizacaoAtendimento('')
    setFimAtualizacaoAtendimento('')
    setInicioConclusaoAtendimento('')
    setFimConclusaoAtendimento('')
    setInicioAgendamentoAtendimento('')
    setFimAgendamentoAtendimento('')
    setExportar(0)
    setOpenModal(false)
    setAtendimentos([])
    setLimiteAtendimentos(8)
    setPaginaAtendimentos(1)
    setTotalAtendimentos(0)
    setFiltroPeriodo(1)
    setTipoFiltroUsuario(0)
    setCampanhaAtendimento([])
    setMotivoDesistenciaAtendimento([])
    setEmpreendimentoAtendimento([])
    setEquipePdvSelecionadaAtendimento([])
    setGerentePdvSelecionadoAtendimento([])
    setUsuarioPdvSelecionadoAtendimento([])
    setEquipePreAtendimentoSelecionadaAtendimento([])
    setGerentePreAtendimentoSelecionadoAtendimento([])
    setUsuarioPreAtendimentoSelecionadoAtendimento([])
    setMidiaAtendimento([])
    setStatusOportunidadeAtendimento([])
    setSubstatusAtendimento([])
    setResponsavelAtendimento([])
    setFiltroContatoAtendimento(true)
    setOptionsSubstatusAtendimento([])
    setIdTipoTarefa("");
  }

  function alterarModificadorEmpreendimento() {
    setModificadorCampoEmpreendimento(modificador => modificador == 1 ? modificador + 1 : 1)
  }

  return (
    <FiltroGlobalContext.Provider value={{
      campanhaSelecionada, setCampanhaSelecionada,
      midiaSelecionada, setMidiaSelecionada,
      origemSelecionada, setorigemSelecionada,
      tipoHistoricoSelecionado,setTipoHistoricoSeleciondado,
      nomeLead, setNomeLead,
      cpfEnvolvido, setCpfEnvolvido,
      emailLead, setEmailLead,
      telefoneLead, setTelefoneLead,
      empreendimentoSelecionado, setEmpreendimentoSelecionado,
      equipePreAtendimentoSelecionada, setEquipePreAtendimentoSelecionada,
      gerentePreAtendimentoSelecionado, setGerentePreAtendimentoSelecionado,
      usuarioPreAtendimentoSelecionado, setUsuarioPreAtendimentoSelecionado,
      equipePdvSelecionada, setEquipePdvSelecionada,
      gerentePdvSelecionado, setGerentePdvSelecionado,
      usuarioPdvSelecionado, setUsuarioPdvSelecionado,
      idOportunidade, setIdOportunidade,
      tempoSemAtualizacao, setTempoSemAtualizacao,
      dataCadastroOportunidadeInicial, setDataCadastroOportunidadeInicial,
      dataCadastroOportunidadeFinal, setDataCadastroOportunidadeFinal,
      statusOportunidadeSelecionada, setStatusOportunidadeSelecionada,
      motivoDesistencia, setMotivoDesistencia,
      faixaValorInicial, setFaixaValorInicial,
      faixaValorFinal, setFaixaValorFinal,
      interesses, setInteresses,
      dadosEnvolvidoFiltro, setDadosEnvolvidoFiltro,
      modificador1, setModificador1,
      filtroFaixa, setFiltroFaixa,
      tabKey, setTabKey,
      optionsSubstatus, setOptionsSubstatus,
      substatusSelecionado, setSubstatusSelecionado,
      tagsSelecionadas, setTagsSelecionadas,
      dataVendaInicial, setDataVendaInicial,
      dataVendaFinal, setDataVendaFinal,
      dataContabilizacaoInicial, setDataContabilizacaoInicial,
      dataContabilizacaoFinal, setDataContabilizacaoFinal,
      agruparPorLeads, setAgruparPorLeads,
      filtrosQualificacao, setFiltrosQualificacao,
      optionsTiposAtendimentos, setOptionsTiposAtendimentos,
      tipoAtendimento, setTipoAtendimento,
      telefoneAtendimento, setTelefoneAtendimento,
      emailAtendimento, setEmailAtendimento,
      criadoPorAtendimento, setCriadoPorAtendimento,
      concluidodoPorAtendimento, setConcluidoPorAtendimento,
      tarefaRealizada, setTarefaRealizada,
      inicioCriacaoAtendimento, setInicioCriacaoAtendimento,
      fimCriacaoAtendimento, setFimCriacaoAtendimento,
      inicioAtualizacaoAtendimento, setInicioAtualizacaoAtendimento,
      fimAtualizacaoAtendimento, setFimAtualizacaoAtendimento,
      inicioConclusaoAtendimento, setInicioConclusaoAtendimento,
      fimConclusaoAtendimento, setFimConclusaoAtendimento,
      inicioAgendamentoAtendimento, setInicioAgendamentoAtendimento,
      fimAgendamentoAtendimento, setFimAgendamentoAtendimento,
      exportar, setExportar,
      openModal, setOpenModal,
      atendimentos, setAtendimentos,
      limiteAtendimentos, setLimiteAtendimentos,
      paginaAtendimentos, setPaginaAtendimentos,
      totalAtendimentos, setTotalAtendimentos,
      filtroPeriodo, setFiltroPeriodo,
      tipoFiltroUsuario, setTipoFiltroUsuario,
      campanhaAtendimento, setCampanhaAtendimento,
      motivoDesistenciaAtendimento, setMotivoDesistenciaAtendimento,
      empreendimentoAtendimento, setEmpreendimentoAtendimento,
      equipePdvSelecionadaAtendimento, setEquipePdvSelecionadaAtendimento,
      gerentePdvSelecionadoAtendimento, setGerentePdvSelecionadoAtendimento,
      usuarioPdvSelecionadoAtendimento, setUsuarioPdvSelecionadoAtendimento,
      equipePreAtendimentoSelecionadaAtendimento, setEquipePreAtendimentoSelecionadaAtendimento,
      gerentePreAtendimentoSelecionadoAtendimento, setGerentePreAtendimentoSelecionadoAtendimento,
      usuarioPreAtendimentoSelecionadoAtendimento, setUsuarioPreAtendimentoSelecionadoAtendimento,
      midiaAtendimento, setMidiaAtendimento,
      statusOportunidadeAtendimento, setStatusOportunidadeAtendimento,
      substatusAtendimento, setSubstatusAtendimento,
      responsavelAtendimento, setResponsavelAtendimento,
      filtroContatoAtendimento, setFiltroContatoAtendimento,
      optionsSubstatusAtendimento, setOptionsSubstatusAtendimento,
      idTipoTarefa, setIdTipoTarefa,
      listaTarefas, setListaTarefas,
      canaisSelecionados, setCanaisSelecionados,
      limparFiltros, configExibirCanalOPV,
      modificadorCampoEmpreendimento, alterarModificadorEmpreendimento,
      aplicarFiltrosLead, setAplicarFiltrosLead,
      oportunidadesEncontradas, setOportunidadesEncontradas,
      mostrarFiltroGlobal, setMostrarFiltroGlobal,
      mostrarListaGlobal, setMostrarListaGlobal,
      totalFiltroGlobal, setTotalFiltroGlobal,
      filtrosGlobal, setFiltrosGlobal
    }}>
      {children}
    </FiltroGlobalContext.Provider>
  )
}
