import React, {useContext, useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import ModalDommus from "../../../components/Modal";
import {Alert, ListGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { OportunidadeContext } from "../../../components/GlobalContext/globalContext";

export default function InformacoesAdicionais({informacoesAdicionais}) {

    const [openModal, setOpenModal] = useState(false);
    const [dadosInformacoesAdicionais, setDadosInformacoesAdicionais] = useState({});
    const {exibirInformacoesMeta} = useContext(OportunidadeContext);

    const formatarChave = (chave) => {
        let arrayPalavras = chave.toString().split('_');
        let retorno = '';
        arrayPalavras.forEach((palavra) => retorno = retorno.concat(palavra.charAt(0).toUpperCase() + palavra.slice(1), " "));
        return retorno;
    };

    useEffect(() => {
      let dados = {}; 
      if(typeof informacoesAdicionais === 'string'){
        dados = JSON.parse(informacoesAdicionais);
      }else{
        dados = informacoesAdicionais;
      }
      if(dados && exibirInformacoesMeta === false){
          delete dados.ANUNCIO;
          delete dados.PAGINA;
          delete dados.FORMULARIO;
          delete dados.GRUPO_DE_ANUNCIOS;
          setDadosInformacoesAdicionais(dados)
      }else{
          setDadosInformacoesAdicionais(dados)
      }
    }, [exibirInformacoesMeta, informacoesAdicionais]);

    const infoList = useMemo(()=>{
      return (dadosInformacoesAdicionais && Object.entries(dadosInformacoesAdicionais).map(([key, value]) => {
        return <ListGroup.Item key={key}>
            <b>{formatarChave(key)}:</b> {value}
        </ListGroup.Item>;
      })) || <></>;
    },[dadosInformacoesAdicionais]);

    return <>
        <OverlayTrigger placement={'top'} overlay={<Tooltip>Informações Adicionais</Tooltip>}>
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => setOpenModal(true)} style={{cursor: 'pointer'}}/>
        </OverlayTrigger>
        <ModalDommus open={openModal} close={() => {
            setOpenModal(false)
        }} titulo={"Informações Adicionais"}>
           { informacoesAdicionais ?
              <>
               <ListGroup>
                    {infoList}                
                </ListGroup>
              </>
              : 
              <Alert variant="info">Não há informações adicionais no momento</Alert>
           }
        </ModalDommus>
    </>

}
