import React, { useContext, useEffect, useState } from 'react';
import '../MenuTopo/index.css';
import logo from '../../assets/logo-w.min.svg';
import logoMobile from '../../assets/logo-mobile.png'
import menu from '../../assets/navigation-menu.svg';
import close from '../../assets/close.png';
import FiltroGlobalOportunidades from "../FiltroGlobalOportunidades";
import NaoDistribuidos from "../NaoDistribuidos";
import Notificacoes from "../Notificacoes";
import DommusOnlineFlag from "../DommusOnlineFlag";
import Agenda from "./Agenda/index.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import '../MenuLateral/index';
import { Icon } from '@iconify/react';
import calendarOutlineBadged from '@iconify-icons/clarity/calendar-outline-badged';
import clipboardData from '@iconify-icons/bi/clipboard-data';
import infoCircleOutlined from '@iconify-icons/ant-design/info-circle-outlined'
import Resumo from "./Resumo";
import guService from "../../services/GuService";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import LogoutService from '../../services/LogoutService';
import ReactTooltip from 'react-tooltip';
import PesquisaGeralInput from '../PesquisaGeralInput';
import { PusherContext } from '../../contexts/PusherContext';
import relatorioService from '../../services/RelatorioService';
import { isMobile, isTablet } from 'react-device-detect';
import NavegadorModulos from '../NavegadorModulos';

export default function MenuTopo({ history }) {
  const [openAgenda, setOpenAgenda] = useState(false);
  const [openAtalho, setOpenAtalho] = useState(false);
  const [ulrVendas, setulrVendas] = useState(localStorage.getItem('@DommusCRM-linkVendas') || '#')
  const pusher = useContext(PusherContext);

  useEffect(()=>{
    pusher && pusher.pusherState && pusher.pusherState.bind('novoRelatorioDeBaseCompleta',function(data) {
      if(data.idUsuario !== Number(guService.getLocalState('idUsuario'))) {
        return () => pusher?.pusherState.unbind()
      }
      Swal.fire({
        titleText: "Relatório",
        text: "Relatório gerado com sucesso",
        icon: "success",
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Efetuar download",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary mr-3",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.isConfirmed) {
          relatorioService.baixarRelatorioOportunidadesDiario(data.tipoRelatorio == 'atendimentos' ? 'atendimentos' : null)
        }
      })
    }) 
    return () => pusher?.pusherState.unbind();
  }, [pusher?.pusherState])

  function logout(e) {
    e.preventDefault();
    // guService.logout()
    // window.location.reload(false);
  }

  function submitHandler(){
    if(openAgenda){
      setOpenAgenda(false);
      setOpenAgenda(true);
    }
  }

  function abrirMenuMobile() {
    const sidebarMobile = document.getElementById('sidebarMobile');
    const asidebar = document.getElementById('asideMobile');
    const asidebarWrapper = document.getElementById('asideWrapperMobile');
    const barsMenuMobile = document.getElementById('barsMenuMobile');


    if (asidebar.classList == 'sidebar hide') {
      asidebar.classList.remove('hide');
      asidebarWrapper.classList.remove('is-closed');
      sidebarMobile.style.display = 'flex';
      barsMenuMobile.src = close;
    } else {
      asidebar.classList.add('hide');
      asidebarWrapper.classList.add('is-closed');
      sidebarMobile.style.display = 'none';
      barsMenuMobile.src = menu;
    }
  }

  function logoutSistema() {
    Swal.fire({
      title: 'Deseja realmente sair do sistema?',
      text: "Confirme para deslogar do sistema!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, sair!',
      cancelButtonText: 'Cancelar!'
    }).then((result) => {
      if (result.isConfirmed) {
        trackPromise( LogoutService.sairPerfilUser().then(response => {
          localStorage.clear();
          window.location.replace(process.env.REACT_APP_GU_FRONT+'/modulo/logout');
        }).catch(error => {
            Swal.fire(
                'Ooops... Ocorreu um erro ao sair do sistema.',
                '',
                'error'
            )
        }) )
      }
    })
  }

  return (
    <>
      <nav className="dommus-menu">
        <div className="left-nav">
          <div className='logo'>
            <picture>
              <img src={isMobile ? logoMobile : logo } alt="CRM - Dommus Sistemas" />
            </picture>
            <span className="modulo-name">
              <span>|</span> 
              <NavegadorModulos/>
            </span>
          </div>
          <div className="input-pesquisa col-6">
            <PesquisaGeralInput />
          </div>
        </div>       

        <div className="right-nav">
          <div className="info-nav">
            <div className="online">
              
              <Dropdown>
                <Dropdown.Toggle className="perfil-dropdown">
                  <DommusOnlineFlag />
                  {!isMobile && 
                    <h1 className="user-name d-none d-md-flex">Olá, {guService.getLocalState('nomeUsuario')}</h1>
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="4" className='boxSair' >
                        <Link style={{color: '#212529'}} to="/perfil">
                          <ul>
                            <li className='liFlexSair'>
                              Meu Perfil
                              <FontAwesomeIcon
                                icon={faUserCog}
                                color="#f7941d"
                                data-tip="Meu Perfil"
                              />
                            </li>
                          </ul>
                        </Link>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="4" className='boxSair' >
                    {/* <Link to="/sair">
                      Sair
										</Link> */}
                    <ul>
                      {/* <a href="#"> */}
                        <li className='liFlexSair'
                          onClick={() => {
                            logoutSistema()
                          }}
                          >
                            Sair
                            <FontAwesomeIcon
                              icon={faPowerOff}
                              color="#f44336"
                              data-tip="Sair"
                            />
                        </li>
                      {/* </a> */}
                    </ul>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <a style={{color: '#fff'}} href={`${ulrVendas}documentacao/public/index.php`} target='_blank' rel="noreferrer" className='central-ajuda'>
              <picture className="picture-topo iconeCockpit">
                <Icon icon={infoCircleOutlined} data-tip={"Central de Ajuda"} />
              </picture>
            </a>
            <picture className="picture-topo iconeCockpit" onClick={() => { setOpenAtalho(statusAtalho => { return !statusAtalho }) }}>
              <Icon icon={clipboardData} width="26" data-tip data-for="acompDiario" />
              <ReactTooltip id="acompDiario" place="top" effect="solid">
                Acompanhamento Diário
              </ReactTooltip>
            </picture>
            {(!isMobile || isTablet) && 
              <picture className={"picture-topo"} onClick={() => {
                setOpenAgenda(statusAtual => {
                  return !statusAtual
                })
              }}>
                <Icon icon={calendarOutlineBadged} data-tip data-for="calendarioTooltip" width="30" />
                <ReactTooltip id="calendarioTooltip" place="top" effect="solid">
                    Calendário
                </ReactTooltip>
              </picture>
            }
            <NaoDistribuidos />

            <Notificacoes />


            <FiltroGlobalOportunidades history={history} onSubmit={submitHandler} />

            <picture className="barsMenuMobile" onClick={() => {
              abrirMenuMobile()
            }}>
              <img src={menu} alt="Ícone de menu" id="barsMenuMobile" />
            </picture>

          </div>
        </div>
      </nav>
      <Agenda openAgenda={openAgenda} closeAgenda={() => {
        setOpenAgenda(false)
      }} />
      <Resumo openAtalho={openAtalho} closeAtalho={() => {
        setOpenAtalho(false)
      }} />
    </>

  )

}
