import React, { useContext, useState } from "react";
import "./index.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileAlt, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import DommusToggle from "../../../../../components/DommusToggle";
import { Controller, useForm } from "react-hook-form";
import { QuestionarioOportunidadeType, QuestionarioPublicoType } from "../../../../../contexts/QuestionarioContext/types";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import QuestionarioService from "../../../../../services/QuestionarioService";
import { swalErro } from "../../../../../helpers/DommusSwal";
import { OportunidadeType } from "../../../Cadastro/types";
import { QuestionarioContext } from "../../../../../contexts/QuestionarioContext";
import { MultiSelect, Option } from "react-multi-select-component";
import { QrCodeLinkQuestionarioPublico } from "./QrCodeLinkQuestionarioPublico";

interface GeracaoLinkQuestionarioPublicoProps {
    questionario: QuestionarioOportunidadeType;
    oportunidade: OportunidadeType;
    closeModal: () => void;
}

type EnvioEnvolvidoFormType = {
    meio_comunicacao: any;
    envolvidos: any;
    id_template: any;
    id_oportunidade?: number;
    id_questionario?: number;
}

const configs = {
    selectSomeItems: "Selecione",
    allItemsAreSelected: "Todos itens selecionados",
    selectAll: "Selecionar todos",
    search: "Pesquisar",
};


export function GeracaoLinkQuestionarioPublico({ questionario, oportunidade, closeModal }: GeracaoLinkQuestionarioPublicoProps) {
    const { handleSubmit, control, register, errors, setError } = useForm<EnvioEnvolvidoFormType>()
    const { adicionarAvalicaoOpiniao } = useContext(QuestionarioContext);
    const [listaTemplate, setListaTemplate] = useState([]);
    const [questionarioPublicoCriado, setQuestionarioPublicoCriado] = useState<QuestionarioPublicoType>({} as QuestionarioPublicoType)

    const handleBuscarTemplates = (meioComunicacao: string) => {
        if (meioComunicacao) {
            trackPromise(QuestionarioService.buscarTemplatesPorMeioComunicacao(meioComunicacao)).then((response: any) => {
                setListaTemplate(response.data);
            }).catch((error) => {
                Swal.fire(
                    'Ooops... Ocorreu um erro ao buscar templates.' + error,
                    '',
                    'error'
                )
            })
        } else {
            setListaTemplate([]);
        }
    }

    const handleOnSubmit = (data: EnvioEnvolvidoFormType) => {
        if(!data.envolvidos.length){
            setError("envolvidos", { type: "custom", message: "Campo obrigatório!" });
            return;
        }
        data.envolvidos = data?.envolvidos?.map((item: any) => item.value);
        data.id_oportunidade = oportunidade.id_oportunidade;
        data.id_questionario = questionario.id_questionario;

        trackPromise(QuestionarioService.dispararLinkEnvolvidos(data)).then((response) => {
            Swal.fire(
                "Pesquisas enviadas com sucesso!",
                '',
                'success'
            );
            closeModal();
        }).catch((error) => {
            Swal.fire(
                `Ooops... ${error?.response.data?.message ?? 'Ocorreu um erro ao enviar links para os envolvidos.'}`,
                '',
                'error'
            )
        })
    }

    const handleGerarLinkAvulso = (dados:any) => {
        Swal.fire({
            titleText: "Gerar link avulso",
            text: `Tem certeza que deseja criar o link público para o questionário "${questionario.descricao}"?`,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, gerar!",
            cancelButtonText: "Não",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((res) => {
            if (res.value) {
                const payload = {
                    id_oportunidade: oportunidade.id_oportunidade,
                    id_questionario: questionario.id_questionario,
                    sla_expira: dados?.sla_expira,
                    origem: "avulso"
                }
                trackPromise(QuestionarioService.gerarQuestionarioPublico(payload)).then((response: any) => {
                    adicionarAvalicaoOpiniao(response?.data?.id_questionario, response?.data);
                    setQuestionarioPublicoCriado(response?.data ?? null);
                }).catch((error) => {
                    let mensagem = "";
                    if (error.response?.data?.message) {
                        mensagem = error.response?.data?.message;
                    } else {
                        mensagem = `Houve um erro ao tentar gerar link avulso. Status: ${error}`
                    }
                    swalErro(mensagem);
                })
            }
        });
    }

    const handleGerarQuestionarioInterno = ()=>{
      Swal.fire({
            titleText: "Gerar questionário",
            text: `Tem certeza que deseja gerar um questionário para preenchimento interno?`,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, gerar!",
            cancelButtonText: "Não",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((res) => {
            if (res.value) {
              const payload = {
                  id_oportunidade: oportunidade.id_oportunidade,
                  id_questionario: questionario.id_questionario,
                  origem: "interno"
              }
              trackPromise(QuestionarioService.gerarQuestionarioPublico(payload)).then((response:any)=>{
                adicionarAvalicaoOpiniao(response?.data?.id_questionario, response?.data);
                setQuestionarioPublicoCriado(response?.data ?? null);
              }).catch((error)=>{
                Swal.fire(
                    `Ooops... ${error?.response.data?.message ?? 'Ocorreu um erro ao gerar questionário interno.'}`,
                    '',
                    'error'
                )
              })
            }
        });
    }

    return (
        <div style={{maxHeight: '75vh', overflowX: 'hidden', overflowY: 'auto'}}>
            <Row>
                <Col sm={12}>
                    <DommusToggle title="Link Avulso: (Preenchido pelo prospect)">
                        <div className="link-avulso-section">
                            <QrCodeLinkQuestionarioPublico questionarioPublico={questionarioPublicoCriado} handleGerarLinkQuestionarioPublico={handleGerarLinkAvulso}/>
                        </div>
                    </DommusToggle>
                </Col>
            </Row>
            {oportunidade.envolvidos_oportunidade?.length ?
                <Row className="mt-1">
                    <Col sm={12}>
                        <DommusToggle title="Enviar para cliente (Preenchido pelo prospect)">
                            <form onSubmit={handleSubmit(handleOnSubmit)} className="envio-envolvido-section">
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Envolvido(s):</Form.Label>
                                    <Controller
                                        control={control}
                                        name={"envolvidos"}
                                        defaultValue={[]}
                                        rules={{ required: true }}
                                        as={
                                            <MultiSelect
                                                options={
                                                    oportunidade.envolvidos_oportunidade.map((envolvido) => {
                                                        return {
                                                            label: envolvido.envolvido.nome,
                                                            value: envolvido.id_envolvido
                                                        }
                                                    }) as Option[]
                                                }
                                                labelledBy=""
                                                value={[]}
                                                overrideStrings={configs}
                                            />
                                        }
                                    />
                                    {errors && errors?.envolvidos  && <small className="form-text text-danger">Nenhum envolvido selecionado.</small>}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Meio Comunicação:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="meio_comunicacao"
                                        ref={register({ required: true })}
                                        onChange={(event) => {
                                            handleBuscarTemplates(event.target.value);
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="email">E-mail</option>
                                        <option value="sms">SMS</option>
                                        <option value="whatsapp">Whatsapp</option>
                                    </Form.Control>
                                    {errors && errors?.meio_comunicacao && <small className="form-text text-danger">Selecione um Meio de Comunicação.</small>}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Template:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="id_template"
                                        ref={register({ required: true })}
                                        disabled={!listaTemplate.length}
                                    >
                                        <option value="">Selecione</option>
                                        {listaTemplate.map((item: any, index: number) => {
                                            return <option key={index} value={item.id}>{item.nome}</option>
                                        })}
                                    </Form.Control>
                                    {errors && errors?.id_template && <small className="form-text text-danger">Selecione um Template.</small>}
                                </Form.Group>
                                <button className="btn btn-success" type="submit">
                                    <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                                </button>

                            </form>
                        </DommusToggle>
                    </Col>
                </Row>
                : <></>
            }
            <Row>
                <Col sm={12}>
                    <DommusToggle title="Registrar resposta (Preenchido pela empresa)">
                        <div className="link-avulso-section">
                            <button 
                                className="btn btn-tipo-link link-avulso" 
                                onClick={() => { 
                                  handleGerarQuestionarioInterno()
                                  closeModal();
                                }}>
                                <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Gerar questionário 
                            </button>
                        </div>
                    </DommusToggle>
                </Col>
            </Row>
        </div>
    )
}
