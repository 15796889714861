import React, { useCallback, useContext, useMemo, useState } from "react";
import "./index.css";
import { QuestionarioPublicoType } from "../../../../../contexts/QuestionarioContext/types";
import DommusToggle from "../../../../../components/DommusToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExclamationCircle, faLink, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { RespostaPublicaItem } from "./RespostaPublicaItem";
import { QuestionarioContext } from "../../../../../contexts/QuestionarioContext";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import QuestionarioService from "../../../../../services/QuestionarioService";
import { celularMask } from "../../../../../components/InputTextMask";
import ModalDommus from "../../../../../components/Modal";
import { QrCodeLinkQuestionarioPublico } from "../GeracaoLinkQuestionarioPublico/QrCodeLinkQuestionarioPublico";
import { swalErro } from "../../../../../helpers/DommusSwal";
import { QuestionarioPublicoOportunidadeForm } from "./QuestionarioPublicoOportunidadeForm";
import { PerguntaType } from "../../../../../contexts/PerguntaContext/types";

interface QuestionarioPublicoItemProps {
    questionarioPublico: QuestionarioPublicoType;
    perguntas?: PerguntaType[];
    expirado?: boolean
}

export function QuestionarioPublicoItem({ questionarioPublico, perguntas, expirado = false }: QuestionarioPublicoItemProps) {
    const { permissoesQuestionarioOportunidade, removerAvaliacaoOpiniao } = useContext(QuestionarioContext);
    const [openModalCopiarLink, setOpenModalCopiarLink] = useState(false);
    const { atualizarAvalicaoOpiniao } = useContext(QuestionarioContext);

    const resolveBadgeStatus = useCallback(() => {
        if(questionarioPublico?.origem === 'interno'){
            return (<div style={{display: "flex", flexDirection: "row", gap: "5px"}}>
              <span className="badge-resposta badge-interno">Preenchimento interno</span>
              {questionarioPublico?.respostas_com_perguntas && questionarioPublico?.respostas_com_perguntas.length ?
                <span className="badge-resposta badge-respondido">Preenchido em: {moment(questionarioPublico?.respostas_com_perguntas[0].criado_em).format("DD/MM/YYYY HH:mm")}</span>
              : <></>}
            </div>)
        }else if (questionarioPublico?.respostas_com_perguntas && questionarioPublico?.respostas_com_perguntas.length) {
            return <span className="badge-resposta badge-respondido">Respondido em: {moment(questionarioPublico?.respostas_com_perguntas[0].criado_em).format("DD/MM/YYYY HH:mm")}</span>
        } else {
            return <span className={`badge-resposta badge-${expirado ? 'expirado' : 'aberto'}`}>{expirado ? "Expirado em: " : "Expira em: "} {moment(questionarioPublico.criado_em).add(questionarioPublico.sla_expira, 'hours').format("DD/MM/YYYY HH:mm")}</span>
        }

    }, [questionarioPublico]);

    const removerQuestionarioPublico = () => {
        Swal.fire({
            title: 'Remoção de Formulário de respostas',
            text: `Tem certeza que deseja remover o formulário de respostas`,
            icon: 'question',
            confirmButtonText: 'Sim, remover.',
            showCancelButton: true,
            cancelButtonText: 'Não, Cancelar.'
        }).then(result => {
            if (result.isConfirmed) {
                trackPromise(QuestionarioService.removerQuestionarioPublico(questionarioPublico.id_questionario_publico)).then((response) => {
                    removerAvaliacaoOpiniao(questionarioPublico.id_questionario, questionarioPublico.id_questionario_publico, expirado);
                }).catch((error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: error?.response?.data?.message ?? `Ocorreu um erro ao remover formulário de respostas.${error}`,
                        icon: 'error'
                    });
                })
            }
        })

    }

    const icones = useMemo(() => {
        return (
            <div className="icons-section">
                <div>
                    {resolveBadgeStatus()}
                </div>
                <div>
                    <span>{`Criado em: ${moment(questionarioPublico.criado_em).format('DD/MM/YYYY')} | ${questionarioPublico?.nome_criado_por ?? "---"}`}</span>
                    {!expirado && questionarioPublico?.respostas_com_perguntas && !questionarioPublico?.respostas_com_perguntas[0]?.criado_em &&
                        <>
                            {!questionarioPublico.link_publico && questionarioPublico?.origem !== 'interno' && 
                                <OverlayTrigger
                                    key="iconError"
                                    placement="top"
                                    overlay={
                                        <Tooltip
                                            id={`link-publico-erro-${questionarioPublico.id_questionario_publico}`}
                                        >
                                            Erro ao gerar link
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                                    </span>
                                </OverlayTrigger>
                            }
                            {questionarioPublico?.origem !== 'interno' && 
                              <OverlayTrigger
                                  key="iconCopy"
                                  placement="top"
                                  overlay={
                                      <Tooltip
                                          id={`link-publico-copiar-${questionarioPublico.id_questionario_publico}`}
                                      >
                                          {!questionarioPublico.link_publico ? "Gerar" : "Copiar"}
                                      </Tooltip>
                                  }
                              >
                                  <button className="btn btn-link" onClick={() => {
                                      setOpenModalCopiarLink(true);
                                  }}>
                                      <FontAwesomeIcon icon={!questionarioPublico.link_publico ? faLink : faCopy} />{!questionarioPublico.link_publico ? "Gerar" : "Copiar"} link
                                  </button>
                              </OverlayTrigger>
                            }
                        </>
                    }
                    {permissoesQuestionarioOportunidade?.remover &&
                        <button className="btn btn-questionario-remover" onClick={() => { removerQuestionarioPublico() }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    }
                </div>
            </div>
        )
    }, [questionarioPublico, permissoesQuestionarioOportunidade])

    const handleRegerarLink = (dados:any)=>{
        Swal.fire({
            titleText: "Gerar link avulso",
            text: `Tem certeza que deseja criar o link público para o questionário?`,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, gerar!",
            cancelButtonText: "Não",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((res) => {
            if (res.value) {
                const payload = {
                    id_questionario_publico: questionarioPublico.id_questionario_publico,
                    id_oportunidade: questionarioPublico.id_oportunidade,
                    id_questionario: questionarioPublico.id_questionario,
                    sla_expira: dados?.sla_expira,
                    origem: "avulso"
                }
                trackPromise(QuestionarioService.gerarQuestionarioPublico(payload)).then((response: any) => {
                    atualizarAvalicaoOpiniao(response?.data?.id_questionario, response?.data);
                }).catch((error) => {
                    let mensagem = "";
                    if (error.response?.data?.message) {
                        mensagem = error.response?.data?.message;
                    } else {
                        mensagem = `Houve um erro ao tentar gerar link avulso. Status: ${error}`
                    }
                    swalErro(mensagem);
                })
            }
        });
    }

    return (
        <>
            <DommusToggle title={""} icones={icones}>
                <div className="resposta-container">
                    <div className="info-section">
                        <div className="dados">
                            <label>Origem:</label>
                            <span>{questionarioPublico?.origem ?? "---"}</span>
                        </div>
                        {questionarioPublico.envolvido ?
                        <>
                            <div className="dados">
                                <label>Envolvido:</label>
                                <span>{questionarioPublico?.envolvido?.nome ?? "---"}</span>
                            </div>
                            <div className="dados">
                                <label>Contato:</label>
                                <span style={{ textTransform: "none" }}>{questionarioPublico?.origem ? (questionarioPublico?.origem === 'email' ? questionarioPublico?.contato : celularMask(questionarioPublico?.contato)) : "---"}</span>
                            </div>
                        </>
                        : <></>
                        }
                    </div>
                    <div className="respostas-section">
                        {questionarioPublico?.origem === 'interno' ? 
                          <QuestionarioPublicoOportunidadeForm questionarioPublico={questionarioPublico} perguntas={perguntas}/>
                        :
                          questionarioPublico?.respostas_com_perguntas && questionarioPublico?.respostas_com_perguntas.length ?
                            questionarioPublico?.respostas_com_perguntas.map((resposta, index) => {
                                return (
                                    <RespostaPublicaItem key={index} resposta={resposta} />
                                )
                            })
                            : expirado ? <></> : <Alert variant="warning">Formulário ainda não foi respondido</Alert>
                        }
                    </div>
                </div>
            </DommusToggle>
            <ModalDommus
                size={"md"}
                open={openModalCopiarLink}
                close={() => setOpenModalCopiarLink(false)}
                titulo="Link Pesquisa Pública"
            >
                <QrCodeLinkQuestionarioPublico questionarioPublico={questionarioPublico} handleGerarLinkQuestionarioPublico={handleRegerarLink}/>
            </ModalDommus>
        </>
    )
}
