import React, {useState, useEffect, useContext} from 'react'
import { Alert, Col, Form, Row } from "react-bootstrap";
import {useForm} from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import EmpreendimentoService from '../../../../services/EmpreendimentoService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from '@fortawesome/free-solid-svg-icons';
import OportunidadeService from '../../../../services/OportunidadeService';
import CampanhaService from '../../../../services/CampanhaService';
import { celularMask } from '../../../../components/InputTextMask';
import stringHelper from "../../../../helpers/StringHelper";
import MidiaService from '../../../../services/MidiaService';
import DommusOptionalSpan from '../../../../components/DommusOptionalSpan';
import './index.css'
import { CanaisContext } from '../../../../contexts/CanaisContext';
import { FiltroGlobalContext } from '../../../../contexts/FiltroGlobalContext';
import DommusRequiredSpan from '../../../../components/DommusRequiredSpan';
import { OportunidadeContext } from '../../../../components/GlobalContext/globalContext';
import LeadService from '../../../../services/LeadService';
import guService from '../../../../services/GuService';
import useTelefoneInternacionalInput from '../../../../hooks/useTelefoneInternacionalInput';

export default function NovoLeadOPV(props) {
    const { register, handleSubmit, errors } = useForm();
    const [nome, setNome] = useState(
        props.oportunidade?.envolvidos_oportunidade[0]?.envolvido?.nome || ""      
    )
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [campanha, setCampanha] = useState('')
    const [empreendimento, setEmpreendimento] = useState('')
    const [origem, setOrigem] = useState('')
    const [empreendimentos, setEmpreendimentos] = useState([])
    const [origens, setOrigens] = useState([])
    const [campanhas, setCampanhas] = useState([])
    const [validaCampos, setValidaCampos] = useState(true)
    const [midia, setMidia] = useState('')
    const [midias, setMidias] = useState([])
    const [alterarEmpreendimento, setAlterarEmpreendimento] = useState(false)
    const [envolvidoSelecionado, setEnvolvidoSelecionado] = useState(0);
    const [idCanal, setIdCanal] = useState("")
    const [travaCampo, setTravaCampo] = useState(false);
    const [campoTravado, setCampoTravado] = useState("");
    const [idProcesso, setIdProcesso] = useState("");
    const [idLead, setIdLead] = useState("");
    const [idOportunidade, setIdOportunidade] = useState("");
    const { buscarCanais, canais } = useContext(CanaisContext)
    const { configExibirCanalOPV } = useContext(FiltroGlobalContext)
    const { atualizarOportunidade } = useContext(OportunidadeContext)
    const [refInputTelefone, objetoInputTelefone, getNumeroInternacional] = useTelefoneInternacionalInput();

    useEffect(() => {
      buscarCanais({ oculto: 0 })
      buscaEmpreendimentos()
      buscaOrigens()
      buscaCampanhas()
      buscaMidias()
      preencherTelefoneEmailLead()
    }, [])

    function preencherTelefoneEmailLead() {
        if(props.oportunidade?.envolvidos_oportunidade[0]?.envolvido?.email) {
            setEmail(props.oportunidade?.envolvidos_oportunidade[0]?.envolvido?.email)
            buscaDados('EMAIL', props.oportunidade?.envolvidos_oportunidade[0]?.envolvido?.email)
        }
        if(props.oportunidade?.envolvidos_oportunidade[0]?.envolvido?.telefone) {
            setTelefone(celularMask(props.oportunidade?.envolvidos_oportunidade[0]?.envolvido?.telefone))
            buscaDados('TELEFONE', celularMask(props.oportunidade?.envolvidos_oportunidade[0]?.envolvido?.telefone))
        }
    }

    const handleOnSubmit = (data) => {
        if(email != "" || telefone != ""){
            setValidaCampos(!validaCampos)
    
            let nvlead = {
                "lead": {
                    "nome": nome,
                    "email": email,
                    "telefone": getNumeroInternacional(),
                    "id_empreendimento": empreendimento,
                    "campanha": {
                        "id_campanha": campanha
                    },
                    "origem": {
                        "id_origem": origem
                    },
                    "midia": {
                        "id_midia": midia    
                    },
                    id_canal: idCanal || null
                },
                "oportunidade": {
                    "oportunidade": {
                        "id_oportunidade": props.oportunidade.id_oportunidade
                    }
                },
                "alterar_empreendimento_opv": alterarEmpreendimento
            }
            salvarNovoLead(nvlead)
        }else{
            setValidaCampos(!validaCampos)
        }

    }

    function salvarNovoLead(nvlead){
     
        trackPromise(
            OportunidadeService.salvarNovoLeadOPV(nvlead).then((response) => {
                Swal.fire({
                    titleText: "Lead inserido com sucesso",
                    icon: "success"
                })
                atualizarOportunidade(response?.data?.lead_oportunidades[0]?.oportunidade?.id_oportunidade);
                props.closeModal()
            }
            ).catch(error => {
                let mensagensErro = error.response.data;
                if(mensagensErro) {
                  Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um ou mais erros ao salvar o lead: " + Object.values(mensagensErro).join('\n'),
                    icon: "warning",
                  });
                } else {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar lead: " + error,
                        icon: "error"
                    })
                }
            })
        )
    }

    async function buscaOrigens(){
        await trackPromise(
            OportunidadeService.buscarOrigens().then((response) => {
                if (response.data !== undefined) {
                    const buscaorigens = Object.values(response.data);
                    setOrigens(buscaorigens);
                }
            }
            ).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar as origens: " + error,
                icon: "error"
            }))
        )
    }

    async function buscaMidias(){
        await trackPromise(
            MidiaService.buscarMidias().then((response) => {
                if (response.data !== undefined) {
                    const buscaMidias = Object.values(response.data);
                    setMidias(buscaMidias);
                }
            }
            ).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar as mídias: " + error,
                icon: "error"
            }))
        )
    }

    async function buscaCampanhas(){
        await trackPromise(
            CampanhaService.buscarCampanhas().then((response) => {
                if (response.data !== undefined) {
                    setCampanhas(response.data);
                }
            }
            ).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
                icon: "error"
            }))  
        )
    }

    async function buscaEmpreendimentos(){
        await trackPromise(
            EmpreendimentoService.buscarEmpreendimentosPorUsuario().then((response) => {
                if (response.data !== undefined) {
                    setEmpreendimentos(response.data);
                }
            }
            ).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar os empreendimentos: " + error,
                icon: "error"
            }))  
        )
    }

    function validaEmail(campo) {
        if (!stringHelper.IsEmail(email) && email.length > 0) {
          Swal.fire({
            titleText: "E-mail inválido",
            text: "Preencha um e-mail válido prosseguir",
            icon: "error",
          });
          setEmail('')
          return;
        }
        buscaDados('EMAIL')
    }

    function handlePrencherDadosEnvolvido(envolvido){
        setEnvolvidoSelecionado(envolvido )
        if(envolvido === ""){
            setNome('');
            setTelefone(0);
            setEmail('');
        }else{
            setNome(props.oportunidade?.envolvidos_oportunidade[envolvido]?.envolvido?.nome || "");
            setTelefone(
                props.oportunidade?.envolvidos_oportunidade[envolvido]?.envolvido?.telefone && 
                celularMask(props.oportunidade?.envolvidos_oportunidade[envolvido]?.envolvido?.telefone) || 
                ""
            );
            setEmail(props.oportunidade?.envolvidos_oportunidade[envolvido]?.envolvido?.email || "");
        }
    }

    function buscaDados(campo, valor) {
        let payload = {
            email: (campo == 'EMAIL' && valor) || (stringHelper.IsEmail(email) && email) || '',
            telefone:  (campo == 'TELEFONE' && valor) || (objetoInputTelefone && getNumeroInternacional && getNumeroInternacional()) || '',
            idOportunidadeDesconsiderar: props.oportunidade?.id_oportunidade
        }
        trackPromise(LeadService.buscarEnvolvidos(payload, campo))
            .then(response => {
                if (response.data.status) {
                    setTravaCampo(true);
                    setIdProcesso(response.data.id);
                    setIdOportunidade(response.data.id_oportunidade);
                    setIdLead(response.data.id_lead);
                    setCampoTravado(response.data.campo)
                } else {
                    setCampoTravado("");
                    setTravaCampo(false);
                }
            }).catch(error => {
                console.log(error)
                console.log(error?.response)
                toastErro('Houve um erro ao buscar pelos envolvidos.')
            })
    }
    
    function montarLinkAcessoProcessoOpvLead() {
        if(idProcesso) {
            return `${guService.getLocalState('linkVendas')}index_ui.php?mgr=MQ==&ui=NjM=&id_processo=${idProcesso}`
        }
        if(idOportunidade) {
            return `https://leads.dommus.com.br/oportunidade/${idOportunidade}`
        }
        return `https://leads.dommus.com.br/leads/represados`
    }

    return (
        <Form onSubmit={handleSubmit(handleOnSubmit)} className="formCadastro">
            <Row>
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Importar dados do envolvido:</Form.Label>
                    <select className="form-control custom-select"
                        id={'envolvidosDados'}
                        name="envolvidosDados"
                        value={envolvidoSelecionado}
                        onChange={e => {
                            handlePrencherDadosEnvolvido(e.target.value)  
                        }}
                    >                        
                        {
                            props.oportunidade.envolvidos_oportunidade.map((item, index) => (
                                <option key={index} value={index}>{`${index + 1}° Envolvido: ${item?.envolvido.nome}`}</option>
                            ))
                        }
                        <option value="" >Cadastrar um novo</option>
                    </select>
                </Form.Group>  
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Nome:</Form.Label>
                    <Form.Control name="nome" 
                        type="text" 
                        value={nome} onChange={(e) => setNome(e.target.value)}
                        placeholder=""
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="6" controlId="formNome">
                    <Form.Label>Email:
                        <DommusOptionalSpan />
                    </Form.Label>
                    <Form.Control name="nome" 
                        type="text" 
                        value={email} onChange={(e) => setEmail(e.target.value)}
                        placeholder=""
                        onBlur={(e) => {validaEmail(e.target.value)}}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formNome">
                    <Form.Label>
                        Telefone: <DommusOptionalSpan />
                    </Form.Label>
                    <Form.Control type="text" ref={(refInputTelefone)} value={telefone} onChange={(e) => setTelefone(celularMask(e.target.value))} onBlur={() => buscaDados("TELEFONE")}/>
                </Form.Group>
            </Row>
            {travaCampo && (
                <Alert variant="warning">
                    <FontAwesomeIcon icon="exclamation-triangle" className="mr-1"/> Já existe {idProcesso && "um processo cadastrado" || 
                        idOportunidade && "uma oportunidade cadastrada" || 
                        idLead && "um lead cadastrado" || 
                    ''} utilizando o <strong>{campoTravado}</strong> digitado. <a href={montarLinkAcessoProcessoOpvLead()} target="_blank" rel="noreferrer">Clique aqui para visualizar.</a>
                </Alert>
            ) || <></>}
            <Row>
                <div className="form-group col-md-6 col">
                    <label>Empreendimento: </label>
                    <select className="form-control custom-select"
                        id={'empreendimento'}
                        name="empreendimento"   
                        value={empreendimento}
                        onChange={e => {
                            setEmpreendimento(e.target.value)  
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        {
                            empreendimentos.map((item, i) => (
                                <option key={i} value={item.id}>{item.nome}</option>
                            ))
                        }
                    </select>
                </div>
                <Form.Group as={Col} md="4">
                    <Form.Label>Deseja alterar o empreendimento da oportunidade?</Form.Label>
                    <br/>
                    <Form.Label id="alterar-empreendimento" className="form-switch">
                        <span className="pr-3">Não</span>
                        <input type="checkbox" onClick={(e) => setAlterarEmpreendimento(!alterarEmpreendimento)}/>
                        <i data-tip={'teste'}></i>
                        <span className="pl-2">Sim</span>
                    </Form.Label>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md={configExibirCanalOPV ? 3 : 4} controlId="formNome">
                    <Form.Label>Campanha: </Form.Label>
                    <select className="form-control custom-select"
                        id={'campanha'}
                        name="campanha"
                        value={campanha}
                        onChange={e => {
                            setCampanha(e.target.value)  
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        {
                            campanhas.map((item, i) => (
                                <option key={i} value={item.id_campanha}>{item.nome}</option>
                            ))
                        }
                    </select>
                </Form.Group>
                <Form.Group as={Col} md={configExibirCanalOPV ? 3 : 4} controlId="formNome">
                    <Form.Label>Mídia: </Form.Label>
                    <select className="form-control custom-select"
                        id={'midia'}
                        name="midia"
                        value={midia}
                        onChange={e => {
                            setMidia(e.target.value)  
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        {
                            midias.map((item, i) => (
                                <option key={i} value={item.id_midia}>{item.descricao}</option>
                            ))
                        }
                    </select>
                </Form.Group>
                <Form.Group as={Col} md={configExibirCanalOPV ? 3 : 4} controlId="formNome">
                    <Form.Label>Origem: </Form.Label>
                    <select className="form-control custom-select"
                        id={'origem'}
                        name="origem"
                        value={origem}
                        onChange={e => {
                            setOrigem(e.target.value)  
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        {
                            origens.map((item, i) => (
                                <option key={i} value={item.id_origem}>{item.origem}</option>
                            ))
                        }
                    </select>
                </Form.Group>
                {configExibirCanalOPV && (
                    <Form.Group as={Col} md="3" controlId="formNome">
                        <Form.Label>Meio de Entrada do Lead: <DommusRequiredSpan /></Form.Label>
                        <Form.Control as="select" value={idCanal} onChange={(event) => setIdCanal(event.target.value)}>
                            <option value="" required>Selecione uma Opção</option>
                            {canais.map(canal => <option value={canal.id_canal}>{canal.nome}</option>)}
                        </Form.Control>
                    </Form.Group>
                )}
            </Row>
            {!validaCampos && <span className='help-text'>Preencha o e-mail ou o telefone para prosseguir.</span>}
            <button type="submit" className="btn btn-success float-right" disabled={travaCampo} style={{ cursor: travaCampo && 'not-allowed' || 'pointer' }}>
                <FontAwesomeIcon icon={faSave}
                    style={{ marginRight: '10px' }} />Salvar
            </button>
            {/* <button type="button" onClick={() => (limparFiltro(), closeModal())} className="btn btn-outline-danger mx-2 float-right">
                <FontAwesomeIcon icon={faTimesCircle}
                    style={{ marginRight: '10px' }} />Limpar Filtro
            </button> */}
                
        </Form> 
    )
}
