import React, { useMemo } from 'react';
import { celularMask } from "../../InputTextMask";
import './style.css'
import { Card, Col, Row } from 'react-bootstrap';
import BibliotecaIcones from '../../BibliotecaIcones';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalcularSLA from '../../../pages/Oportunidades/ListaOportunidades/ColunaOportunidade/OportunidadeCard/CalcularSLA';
import { faBars, faBuilding, faChevronDown, faChevronUp, faMinusSquare, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { parseSla } from '../../../pages/Oportunidades/Visualizacao/FunilOportunidades';

export default function ItemListaFiltroGlobalLinha({ oportunidade, oportunidadesSelecionadas, setarOpvsSelecionadas }) {
    const user = useMemo(() =>{
      return{
        'nome': oportunidade.envolvido_nome != null && oportunidade.envolvido_nome != "" ? oportunidade.envolvido_nome : oportunidade.lead_nome,
        'celular': oportunidade.envolvido_telefone != null && oportunidade.envolvido_telefone != "" ? oportunidade.envolvido_telefone : oportunidade.lead_telefone,
        'email': oportunidade.envolvido_email != null && oportunidade.envolvido_email != "" ? oportunidade.envolvido_email : oportunidade.lead_email
      }
    },[oportunidade])

    function handleSelecionaOportunidade(oportunidade) {
        const selecionados = [...oportunidadesSelecionadas];
        let indexOportunidade = -1;

        selecionados.some((item, index) => {
          if (item.id_oportunidade === oportunidade.id_oportunidade) {
            indexOportunidade = index;
            return true;
          }
        });

        if (indexOportunidade >= 0) {
          selecionados.splice(indexOportunidade, 1);
        } else {
          selecionados.push(oportunidade);
        }
        setarOpvsSelecionadas(selecionados);
    }

    const nivelInteresse = useMemo(()=>{
        switch (oportunidade?.nivel_interesse) {
          case 'Muito Frio':
              return (
                <div className='item-oportunidade'>
                  <div className='d-flex flex-column'>
                      <FontAwesomeIcon
                          style={{fontSize: 14}}
                          color="#3D56B2"
                          icon={faChevronDown}
                          className="iconDouble"
                      />
                      <FontAwesomeIcon
                          style={{fontSize: 14}}
                          color="#3D56B2"
                          icon={faChevronDown}
                      />
                  </div>
                  <span style={{color: "#3D56B2"}}>{oportunidade?.nivel_interesse}</span>
                </div>
              );
          case 'Frio':
            return (
              <div className='item-oportunidade'>
                <FontAwesomeIcon
                    style={{fontSize: 14}}
                    color="#3D56B2"
                    icon={faChevronDown}
                />
                <span style={{color: "#3D56B2"}}>{oportunidade?.nivel_interesse}</span>
              </div>
            );
          case 'Normal':
            return(
              <div className='item-oportunidade'>
                <FontAwesomeIcon
                    style={{fontSize: 14}}
                    color="#FFAB00"
                    icon={faBars}
                />
                <span style={{color: "#FFAB00"}}>{oportunidade?.nivel_interesse}</span>
              </div>
            );
          case 'Quente':
            return(
              <div className='item-oportunidade'>
                <FontAwesomeIcon
                    style={{fontSize: 14}}
                    color="#FF5C58"
                    icon={faChevronUp}
                />
                <span style={{color: "#FF5C58"}}>{oportunidade?.nivel_interesse}</span>
              </div>
            );
          case 'Muito Quente':
            return(
              <div className='item-oportunidade'>
                <div className='d-flex flex-column'>
                    <FontAwesomeIcon
                        style={{fontSize: 14}}
                        color="#FF5C58"
                        icon={faChevronUp}
                        className="iconDouble"
                        />
                    <FontAwesomeIcon
                        style={{fontSize: 14}}
                        color="#FF5C58"
                        icon={faChevronUp}
                        />
                </div>
                <span style={{color: "#FF5C58"}}>{oportunidade?.nivel_interesse}</span>
              </div>
            )
            default:
              return(
                <div className='item-oportunidade'>
                  <FontAwesomeIcon
                  style={{fontSize: 14}}
                  color="#666"
                  icon={faMinusSquare}
                  />
                  <span>{"Sem definição"}</span>
                </div>
              )
            }
    },[oportunidade]);

    return (
        <td>
          <Card.Link
            className="card-pesquisa-geral w-100"
            onClick={() => { window.open(`/oportunidade/${oportunidade.id_oportunidade}`); }}
          >
              <input
                  className='input-selecionar-oportunidade'
                  type="checkbox"
                  id={"opv" + oportunidade.id_oportunidade}
                  value={1}
                  onClick={(e)=>{e.stopPropagation()}}
                  checked={Boolean (oportunidadesSelecionadas.find( item =>
                      oportunidade.id_oportunidade === item.id_oportunidade
                    ))}
                  onChange={() => {
                    handleSelecionaOportunidade(oportunidade)
                  }}
              />

              <div className="midia-icone-pesquisa" >
                <BibliotecaIcones
                    optionsSelectComIcones={''} setIdSelect={''} chaveLabelSelect={''}
                    chaveIdIconeSelecionado="" sizeIcone={'1x'}
                    colorIcone={oportunidade.midia_cor}
                    value={''} setValue={''}
                    estilo={''} iconeSelecionado={''}
                    chaveIcone={oportunidade.midia_icone} tipoExibicao={'icone'}
                />
              </div>
              <div className='dados-pesquisa-geral m-0 w-100'>
                <Row>
                  <Col>
                    <div className='opv-section'>
                      <span className='opv'>
                        OPV-{oportunidade.id_oportunidade}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className='info-section w-100'>
                  <Col sm={5}>
                    <div className='contato-info'>
                      <span className='item-contato'>
                        <b>Nome:</b> <div className='span-replace'>{user?.nome}</div>
                      </span>
                      <span className='item-contato'>
                        <b>Telefone:</b> <div className='span-replace'>{celularMask(user?.celular ?? "") ?? "---"}</div>
                      </span>
                      <span className='item-contato'>
                        <b>Email:</b> <div className='span-replace'>{user?.email ?? "---"}</div>
                      </span>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className='oportunidade-section'>
                      <div className='item-oportunidade'>
                        <FontAwesomeIcon icon={faSuitcase}/>
                        <span>{oportunidade?.atendente}</span>
                      </div>
                      <div className='item-oportunidade'>
                        <FontAwesomeIcon icon={faBuilding}/>
                        <span>{oportunidade?.nome_empreendimento}</span>
                      </div>
                      {nivelInteresse}
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='data-section'>
                      <span>{moment(oportunidade.ultima_alteracao).format("DD/MM/YYYY HH:MM")}</span>
                      <div className='badgeTempo'>
                        <CalcularSLA
                            date={oportunidade.ultima_alteracao}
                            sla={parseSla("")}
                            sla_expira={parseSla("")}
                        />
                      </div>
                      <div className='etapa-item text-center' style={{background: oportunidade?.etapa_cor}}>
                        {oportunidade?.etapa_nome}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
          </Card.Link>
        </td>
    );

}
