import { trackPromise } from "react-promise-tracker";
import { FunilType } from "../pages/Configuracoes/FunilDeVendas/Funil/types";
import crm from "./crm";

export type ReordenarEtapasDoFunilType = {
    id_funil_status_oportunidade: number
    ordem: number
}

export const FunilService = {
    index: (parametros = {}) => {
        return trackPromise(crm.get('/funil', { params: parametros }))
    },
    store: (data: FunilType) => {
        return trackPromise(crm.post('/funil', data))
    },
    update: (id_funil: number, data: FunilType) => {
        return trackPromise(crm.put(`/funil/${id_funil}`, data))
    },
    reordenarEtapasDoFunil: (id_funil: number, payload: ReordenarEtapasDoFunilType[]) => {
        return trackPromise(crm.put(`/funil/${id_funil}/reordenar`, {ordem: payload}))
    }
}
