import React, { useState, useEffect, useCallback } from 'react';
import { Icon } from '@iconify/react';
import calendarIcon from '@iconify-icons/ion/calendar';
import '../MenuNavigationMobile/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Agenda from '../MenuTopo/Agenda';
import ModalDommus from '../../components/Modal';
import { trackPromise } from "react-promise-tracker";
import OportunidadeService from '../../services/OportunidadeService';
import { NovaOportunidade } from '../../pages/Oportunidades/NovaOportunidade';
import EmpreendimentoService from '../../services/EmpreendimentoService';
import equipeService from "../../services/EquipeService";
import ModalOferta from "../../pages/OfertaAtiva/ModalOferta";
import swal from 'sweetalert';
import {
  faFilterCircleDollar,
  faPlus,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import '../../pages/DetalheOportunidade/Cadastro/index';
import roundDashboard from '@iconify-icons/ic/round-dashboard';


export default function MenuTopo({ history, listaLeads, dados, oportunidade, interesses, atendimentos, anexos, pendencias }) {
  const [openAgenda, setOpenAgenda] = useState(false);
  const [openNovaOportunidade, setOpenNovaOportunidade] = useState(false);
  const [origens, setOrigens] = useState([]);
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [oportunidades, setOportunidades] = useState([]);
  const [oportunidadesTodas, setOportunidadesTodas] = useState([]);
  const [ativarScroll, setAtivarScroll] = useState(true);
  const [totalVisualizacoes, setTotalVisualizacoes] = useState(0);
  const [equipes, setEquipes] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [ofertaModal, setOfertaModal] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [abaSelecionada, setAbaSelecionada] = useState('cadastro');
  const [ofertas, setOfertas] = useState([]);

  useEffect(() => {
    trackPromise(
      Promise.all([
        OportunidadeService.buscarPorStatus(),
        EmpreendimentoService.buscarEmpreendimentos(),
        equipeService.buscarEquipes(),
        OportunidadeService.buscarOrigens(),
      ]).then((response) => {
        const local_oportunidades = Object.values(response[0].data);
        setOportunidades(local_oportunidades);
        if (!ativarScroll) {
          const local_totalVisualizacoes = OportunidadeService.calcularTotalVisualizacoesListaPorStatus(oportunidades);
          setTotalVisualizacoes(local_totalVisualizacoes)
        }
        const local_oportunidadesTodas = oportunidades.slice();
        setOportunidadesTodas(local_oportunidadesTodas)
        const local_empreendimentos = response[1].data;
        setEmpreendimentos(local_empreendimentos)
        const local_equipes = response[2].data;
        setEquipes(local_equipes)
        const local_origens = response[3].data;
        setOrigens(local_origens)
      }
      ).catch(error => swal("Ooops...", "Ocorreu um erro ao buscar as dados: " + error, "error"))
    );

    trackPromise(
      OportunidadeService.buscarTotais().then((response) => {
        setTotalCount(response.data);
        setTotalVisualizacoes(response.data.visualizacoes)
      }, (error) => {
        swal("Ooops...", "Ocorreu um erro ao buscar as oportunidades: " + error.data, "error");
      })
    )

  }, [])

  const handleNovaOfertaModal = useCallback(() => {
    setOfertaModal(null);
    setOpenModal(true);
  }, []);



  function carregaLista() {
    trackPromise(
      OportunidadeService.buscarPorStatus().then(
        (response) => {
          const oportunidadesRetorno = Object.values(response.data);
          const todasOportunidades = oportunidadesRetorno.slice();
          setOportunidadesTodas(todasOportunidades);
          setOportunidades(oportunidadesRetorno)
        },
        (error) => {
          swal("Ooops...", "Ocorreu um erro ao buscar as oportunidades: " + error.data, "error");
        }
      )
    )
  }

  const handleAddListaOferta = useCallback(novaOferta => {
    setOfertas(current => [...current, novaOferta]);
    setOfertaModal(novaOferta);
    setOpenModal(true);
    setAbaSelecionada('base-de-leads')
  }, [openModal, ofertaModal]);

  const handleAtualizarListaOferta = useCallback(novaOferta => {
    const listaAtual = [...ofertas];
    listaAtual[ofertas.findIndex(oferta => oferta.id_oferta_ativa === novaOferta.id_oferta_ativa)] = novaOferta;
    setOfertas(listaAtual);
  }, [ofertas]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
  }, []);

  function handleOfertaAlteracao(oferta_id, novaOferta) {

    const ofertaIndex = ofertas.findIndex(item => item.id_oferta_ativa === oferta_id);
    const novasOfertas = [...ofertas];
    novasOfertas[ofertaIndex] = novaOferta;
    setOfertaModal(novaOferta);
    setOfertas(novasOfertas)
  }

  function handleModificaBotao() {
    const path = window.location.pathname
    if (path == '/oferta-ativa') {
      handleNovaOfertaModal()
    } else {
      setOpenNovaOportunidade(true)
    }
  }


  return (
    <>
      <div className="dommus-menu-mobile">

        <ul className='navigation'>
          <li>
            <a className='navigation-menu-footer' href='/'>
              <FontAwesomeIcon icon={faFilterCircleDollar} color="#4caf50"/>
              <span className='title-navigation-actions'>OPVS</span>
            </a>
          </li>
          <li>
            <a className='navigation-menu-footer' href='/agenda-do-usuario'>
              <Icon icon={calendarIcon} color='#3f51b5'/>
              <span className='title-navigation-actions'>AGENDA</span>
            </a>
          </li>
          <li
            className="meio-footer-mobile"
            onClick={() => { handleModificaBotao() }}
          >
            <FontAwesomeIcon icon={faPlus} color="#fff" />
          </li>
          <li>
            <a className='navigation-menu-footer' href='/dashboard' >
              <Icon icon={roundDashboard} color='#f44336'/>
              <span className='title-navigation-actions'>D-BOARD</span>
            </a>
          </li>
          <li>
            <a className='navigation-menu-footer' href='/filas-atendimento'>
              <FontAwesomeIcon icon={faUserGroup} color="#183b64"/>
              <span className='title-navigation-actions'>FILA</span>
            </a>
          </li>
        </ul>

      </div>
      <Agenda openAgenda={openAgenda} closeAgenda={() => {
        setOpenAgenda(false)
      }} />
      <ModalDommus open={openNovaOportunidade}
        close={() => setOpenNovaOportunidade(false)}
        content={<NovaOportunidade empreendimentos={empreendimentos}
          midias={[]}
          origens={origens}
          closeModal={() => { setOpenNovaOportunidade(false) }}
          campanhas={[]}
          inserirLista={() => {
            window.location.reload();
          }}
          />}
        titulo="Cadastrar nova oportunidade" />

      <ModalDommus open={openModal} close={handleCloseModal} titulo="Base de Oferta Ativa">
        <ModalOferta
          handleAddListaOferta={handleAddListaOferta}
          handleAtualizarListaOferta={handleAtualizarListaOferta}
          closeModal={handleCloseModal}
          oferta={ofertaModal}
          ofertaAlteracao={handleOfertaAlteracao}
          tabKey={abaSelecionada}
          setTabKey={setAbaSelecionada}
        />
      </ModalDommus>
    </>

  )

}
