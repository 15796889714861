import React, {useState, useEffect, useContext} from "react";
import "./index.css";
import cross from "../../assets/cross.svg";
import {Button, Alert} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";
import CampanhaService from "../../services/CampanhaService";
import OportunidadeService from "../../services/OportunidadeService";
import RelatorioService from "../../services/RelatorioService";
import swal from "sweetalert";
import FormFiltroPadrao from "../FormFiltroPadrao";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEraser, faFileExcel, faFilter,faSearch, faTable, faTasks} from "@fortawesome/free-solid-svg-icons";
import ItemListaFiltroGlobal from "./ItemListaFiltroGlobal";
import ReactTooltip from "react-tooltip";
import fileDownload from "js-file-download";
import AlteracaoMassa from "../../pages/Oportunidades/AlteracaoMassa";
import ModalDommus from "../../components/Modal";
import { EmpreendimentoContext, OportunidadeContext } from "../GlobalContext/globalContext";
import { cachearContexto } from "../../helpers/cachearContexto";
import MidiaService from "../../services/MidiaService";
import { toastSucesso } from "../../helpers/DommusSwal";
import { FormBaseDisparoMassa } from "../FormBaseDisparoMassa";
import { FiltroGlobalContext } from "../../contexts/FiltroGlobalContext";

function FiltroGlobalOportunidades({ onSubmit }) {
    const [modalAltMassa, setmodalAltMassa] = useState(false);
    const [modalDisparoEmMassa, setModalDisparoEmMassa] = useState(false);
    const [opvsSelecionadas, setopvsSelecionadas] = useState([]);
    const [tamanhoPagina, setTamanhoPagina] = useState(8);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [todosSelecionados, setTodosSelecionados] = useState(false);
    const { campanhas, setCampanhas, midias, setMidias } = useContext(OportunidadeContext)
    const { limparFiltros, oportunidadesEncontradas, setOportunidadesEncontradas, mostrarFiltroGlobal, setMostrarFiltroGlobal, mostrarListaGlobal, setMostrarListaGlobal, totalFiltroGlobal, setTotalFiltroGlobal, filtrosGlobal, setFiltrosGlobal} = useContext(FiltroGlobalContext);
    const { gerentes, operadores } = useContext(EmpreendimentoContext)

    useEffect(() => {
        cachearContexto(campanhas, setCampanhas, CampanhaService.buscarTodasCampanhas)
        cachearContexto(midias, setMidias, MidiaService.buscarTodasMidias)
    }, [])

    useEffect(() => {
        if (!(mostrarFiltroGlobal) && mostrarListaGlobal) {
            setMostrarListaGlobal(false);
        }
    }, [mostrarFiltroGlobal]);

    useEffect(() => {
        if ( mostrarListaGlobal )
        {
            trackPromise(
                OportunidadeService.buscarOportunidadesPaginado(tamanhoPagina,page,filtrosGlobal)
                    .then((res) => {
                        if ( res.data )
                        {
                            const novaLista = res.data;
                            let count = novaLista.count;

                            setOportunidadesEncontradas(novaLista.lista);
                            setTotalFiltroGlobal(count);
                            setMostrarListaGlobal(true);
                        }
                    })
            )
        }
    },[page,tamanhoPagina, refresh])

    const submitHandler = (filtros) => {
        setFiltrosGlobal(filtros)
        if (filtros.exportar === false) {
            filtros.oportunidade.desconsiderarFunilUsuario = true
            trackPromise(
                OportunidadeService.buscarOportunidadesPaginado(tamanhoPagina,page,filtros).then((res) => {
                    onSubmit(true);
                    if ( res.data )
                    {
                        const novaLista = res.data;
                        let count = novaLista.count;

                        setOportunidadesEncontradas(novaLista.lista);
                        setTotalFiltroGlobal(count);
                        setMostrarListaGlobal(true);
                        if(filtros.criar_base_disparo){
                            toastSucesso.fire("Criado base de disparo com sucesso");
                        }
                    }
                })
            );
        }         
        else {
            filtros.oportunidade.desconsiderarFunilUsuario = true
            trackPromise(
                RelatorioService.relatorioGeral(filtros)
                    .then((response) => {
                        fileDownload(
                            response.data,
                            "relatorio-" + new Date().toISOString() + ".csv"
                        );
                        return true;
                    })
                    .catch((error) => {
                        if(error.response.status === 404){
                            swal(
                                "Ooops...",
                                "Nenhuma oportunidade encontrada!",
                                'info'
                            )
                        }else if(error.response.status === 422){
                            swal(
                               "Ooops...",
                               error.response.data.message,
                                "info"
                            );
                        }else{
                            swal(
                                "Ooops...",
                                "Ocorreu um erro ao gerar o relatório, entre em contato com o suporte.",
                                "error"
                            );
                        }
                        return false;
                    })
            );
        }
    };

    function oportunidadesSelecionadas(opvs) {
        setopvsSelecionadas(opvs);
    }

    return (
        <>
            <div
            onClick={() => setMostrarFiltroGlobal(true)}
                className={"picture-topo"}
                data-tip
                data-for="filtroTip"
            >
                <FontAwesomeIcon
                    icon={faSearch}
                />
            </div>
            <ReactTooltip id="filtroTip" place="top" effect="solid">
                Filtro Geral
            </ReactTooltip>
            {mostrarFiltroGlobal && gerentes.length && operadores.length && campanhas.length && midias.length && (
                <div className="search-wrapper-filtro-global">
                    <div className="search-content">
                        <div className="search-title">
                            <h3>FILTRO</h3>
                            <div className={"filter-right-nav"}>
                                {mostrarListaGlobal && (<div className="text-dark mr-2">
                                    <strong>Total: {totalFiltroGlobal}</strong>
                                </div>) || <></>}
                                <div className="close-search" id="close-search">
                                    <Button
                                        variant={"link"}
                                        onClick={() => {
                                            setMostrarFiltroGlobal(false)
                                            setTimeout(()=>{
                                              setPage(1);
                                              setTotalFiltroGlobal(0);
                                              setOportunidadesEncontradas([]);
                                            },100)
                                        }}
                                        className="bg-white"
                                    >
                                        <picture>
                                            <img src={cross} alt="Fechar"/>
                                        </picture>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="filter-wrapper">
                            {(mostrarListaGlobal && (
                                <>
                                    <div className={"conteudoPagina"}>
                                        {oportunidadesEncontradas.length > 0 ? (
                                            <ItemListaFiltroGlobal
                                                oportunidadesSelecionadas={oportunidadesSelecionadas.bind(
                                                    this
                                                )}
                                                oportunidades={oportunidadesEncontradas}
                                                setLimit={setTamanhoPagina}
                                                limit={tamanhoPagina}
                                                setPage={setPage}
                                                page={page}
                                                total={totalFiltroGlobal}
                                                setTodosSelecionados={(booleano) => setTodosSelecionados(booleano)}
                                            />
                                        ) : (
                                            <Alert variant={"info"}>
                                                Nenhuma oportunidade encontrada
                                            </Alert>
                                        )}
                                    </div>
                                    <div className={"d-flex justify-content-end mt-3"}>
                                        <Button
                                            className="btnAltMassa mr-3 dommus-button"
                                            type={"button"}
                                            disabled={!oportunidadesEncontradas.length}
                                            onClick={() => {
                                                setModalDisparoEmMassa(true);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTable}
                                                style={{marginRight: "15px"}}
                                            />{" "}
                                            Criar Base Disparo
                                        </Button>
                                        <Button 
                                          type="button" 
                                          className={"btnFiltroWrapper dommus-button mr-3"} 
                                          onClick={() => {
                                            limparFiltros('oportunidades')
                                            setMostrarListaGlobal(false)
                                            setTimeout(()=>{
                                              setPage(1);
                                              setTotalFiltroGlobal(0);
                                              setOportunidadesEncontradas([]);
                                            },100)
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEraser} /> Limpar
                                        </Button>
                                        <Button
                                            className="mr-3"
                                            variant={"success"}
                                            type={"button"}
                                            onClick={() => {
                                              submitHandler({...filtrosGlobal, exportar:true});
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                style={{marginRight: "15px"}}
                                            />
                                            Exportar
                                        </Button>
                                        <Button
                                            className="btnAltMassa mr-3"
                                            variant={"success"}
                                            type={"button"}
                                            onClick={() => {
                                                setmodalAltMassa(true);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTasks}
                                                style={{marginRight: "15px"}}
                                            />{" "}
                                            Alteração em Massa
                                        </Button>
                                        <Button
                                            variant={"success"}
                                            type={"button"}
                                            onClick={() => {
                                                setMostrarListaGlobal(false);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFilter}
                                                style={{marginRight: "15px"}}
                                            />
                                            Novo Filtro
                                        </Button>
                                    </div>
                                </>
                            )) || (
                                <FormFiltroPadrao
                                    submitHandler={submitHandler}
                                    campanhas={campanhas}
                                    midias={midias}
                                    gerentes={gerentes}
                                    operadores={operadores}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) || <></>}
            <ModalDommus
                open={modalAltMassa}
                close={() => setmodalAltMassa(false)}
                content={
                    <AlteracaoMassa
                        opvsSelecionadas={opvsSelecionadas}
                        gerentes={gerentes}
                        operadores={operadores}
                        closeModal={() => {
                            setmodalAltMassa(false);
                        }}
                        todasOpvsSelecionadas={todosSelecionados}
                        totalOpvsSelecionadas={totalFiltroGlobal}
                        filtrosOportunidade={filtrosGlobal}
                    />
                }
                titulo="Alteração em Massa"
            />
            <ModalDommus
                size="md"
                open={modalDisparoEmMassa}
                close={() => setModalDisparoEmMassa(false)}
                content={
                    <FormBaseDisparoMassa filtros={filtrosGlobal} closeModal={()=>{setModalDisparoEmMassa(false)}}/>
                }
                titulo="Criar base disparo em massa"
            />
        </>
    );
}

export default FiltroGlobalOportunidades;
