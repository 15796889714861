import crm from './crm';

const QuestionarioService = {
	buscar: () => {
        return crm.get('/questionario');
    },
    store: (dados) => {
        let uri = `/questionario`;
        return crm['post'].call(crm, uri, dados);
    },
	update: (id, dados) => {
        let uri = '/questionario/' + id;
        return crm['put'].call(crm, uri, dados);
    },
    delete: (id) =>{
        let uri = '/questionario/' + id;
        return crm['delete'].call(crm, uri);
    },
    alterarQuestionarioPergunta: (idQuestionario, idPergunta, dados) => {
        let uri = `/questionario/${idQuestionario}/pergunta/${idPergunta}`;
        return crm['put'].call(crm, uri, dados);
    },
    deletePergunta: (idQuestionario, idPergunta) => {
        let uri = `/questionario/${idQuestionario}/pergunta/${idPergunta}`;
        return crm['delete'].call(crm, uri);
    },
    addPerguntasQuestionario: (idQuestionario, dados) => {
        let uri = `/questionario/${idQuestionario}/perguntas`;
        return crm['post'].call(crm, uri, dados);
    },
    reordenarPerguntasQuestionario: (idQuestionario, ordem) => {
        let uri = `/questionario/${idQuestionario}/perguntas`;
        return crm['put'].call(crm, uri, {ordem});
    },    
    storeRespostasQuestionario: (idOportunidade, idQuestionario, respostas) =>{
        let uri = `resposta/oportunidade/${idOportunidade}/questionario/${idQuestionario}`;
        return crm['post'].call(crm, uri, {respostas});
    },
    buscarRespostasPorOportunidade: (idOportunidade)=>{
        let uri = `resposta/oportunidade/${idOportunidade}`;
        return crm['get'].call(crm, uri);
    },
    buscarQuestionarioOportunidade: (idOportunidade)=>{
        let uri = `/questionario/oportunidade/${idOportunidade}`;
        return crm['get'].call(crm, uri);
    },
    buscarPermissoes: ()=>{
        let uri = `questionario/oportunidade/permissoes`;
        return crm['get'].call(crm, uri);
    },
    storeRespostasQuestionarioPublico: (idQuestionarioPublico, respostas) =>{
        let uri = `questionario-publico/${idQuestionarioPublico}/respostas`;
        return crm['post'].call(crm, uri, {respostas});
    },
    buscarTemplatesPorMeioComunicacao: (meioComunicacao) => {
        let uri = `questionario-publico/template/meio-comunicacao/${meioComunicacao}`;
        return crm['get'].call(crm, uri);        
    },
    gerarQuestionarioPublico: (dados)=>{
        let uri = `questionario-publico`;
        return crm['post'].call(crm, uri, dados);
    },
    dispararLinkEnvolvidos: (dados) => {
        let uri = `questionario-publico/disparo-envolvidos`;
        return crm['post'].call(crm, uri, dados);
    },
    removerQuestionarioPublico: (idQuestionarioPublico)=>{
        let uri = `questionario-publico/${idQuestionarioPublico}`;
        return crm['delete'].call(crm, uri);
    },

};

export default QuestionarioService;
