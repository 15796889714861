import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { UserGuardType } from "..";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import { CorretorType, EquipeType, GerenteEquipeType } from "../../../../OfertaAtiva/ModalOferta/BaseDeLeads/Filtros/types";
import { EmpreendimentoContext, OportunidadeContext } from "../../../../../components/GlobalContext/globalContext";

interface DistributionEquipeAtendimentoProps {
    typeUser: number
    selectedEquipe: number | null
    equipes: Array<EquipeType>
    setSelectedEquipe: (id_equipe: number) => void
    selectedGerente: number | null
    setSelectedGerente: (id_gerente: number) => void
    selectedAtendente: number | null
    setSelectedAtendente: (id_atendente: number) => void
    typeEquipe: string
    guardGerente: UserGuardType
    guardAtendente: UserGuardType
    setGuardGerente: (obj: UserGuardType) => void
    setGuardAtendente: (obj: UserGuardType) => void,
    refreshEmpreendimento: boolean
    permissaoEditar?: boolean
}

export function DistributionEquipeAtendimento({
    typeUser,
    selectedEquipe,
    equipes,
    setSelectedEquipe,
    selectedGerente,
    setSelectedGerente,
    selectedAtendente,
    setSelectedAtendente,
    typeEquipe,
    guardGerente,
    guardAtendente,
    setGuardGerente,
    setGuardAtendente,
    refreshEmpreendimento,
    permissaoEditar = true,
}: DistributionEquipeAtendimentoProps) {
    const [editMode, setEditMode] = useState(false)
    const [gerentes, setGerentes] = useState([] as Array<GerenteEquipeType>)
    const [atendentes, setAtendentes] = useState([] as Array<CorretorType>)
    const { oportunidadeSelecionada } = useContext(OportunidadeContext);
    const global = useContext(EmpreendimentoContext);

    useEffect(()=>{
        if(oportunidadeSelecionada){
            setEditMode(false);
        }
    },[oportunidadeSelecionada])

    useEffect(() => {
        setGerentes([])
        setAtendentes([])
    }, [refreshEmpreendimento])

    useEffect(() => {
        handlesetGerentes(selectedEquipe)
    }, [selectedEquipe, equipes])

    useEffect(() => {
        handleSetAtendentes(selectedGerente)
    }, [selectedGerente, gerentes])

    function handlesetGerentes(id_equipe: number | null) {
        if(id_equipe === 0) {
            setSelectedEquipe(0)
            setSelectedGerente(0)
            setSelectedAtendente(0)

            return
        }
        if(!id_equipe) return
        const newGerentes = equipes?.find(equipe => Number(equipe.id_equipe) == id_equipe)?.gerentes.filter((item)=>item.ativo_equipe === 'S' || Number(item?.id_usuario) === Number(selectedGerente) );
        if(newGerentes) {
            setSelectedEquipe(Number(id_equipe))
            setGerentes(newGerentes)
        }
    }

    function handleSetAtendentes(id_gerente: number | null) {
        if(id_gerente === 0) {
            setSelectedGerente(0)
            setSelectedAtendente(0)

            return
        }
        if(!id_gerente) return
        const newAtendentes = gerentes?.find(gerente => Number(gerente.id_usuario) == id_gerente)?.corretores.filter((item)=>item.ativo_equipe === 'S' || Number(item?.id_usuario) === Number(selectedAtendente))
        if(newAtendentes) {
            setSelectedGerente(Number(id_gerente))
            setAtendentes(newAtendentes)
        }
    }

    const equipeSelecionada = global.equipes.find(equipe => Number(equipe.id_equipe) === Number(selectedEquipe));
    

    return (
        <>
            <Row className="row-cadastro-meio">
                <Form.Group as={Col} md={12}>
                    <Form.Label>
                        <div className="divEdit" style={{ fontSize: 14, fontWeight: "bold" }}>
                            <div style={{ fontSize: 14, fontWeight: "bold" }}>
                                {typeEquipe == "A" ? 'Equipe Pré Atendimento:' : 'Equipe PDV:'}
                                {typeEquipe == "A" && [25, 26].includes(typeUser) || typeEquipe == "P" && [7, 8].includes(typeUser) ? (
                                    <DommusRequiredSpan />
                                ) : (
                                    <></>
                                )}
                            </div>
                            {permissaoEditar &&
                                <div className="dataTipEdit" onClick={(e) => setEditMode(!editMode)} data-tip="Editar">
                                    <FontAwesomeIcon icon={faEdit} color="#12a2b8" />
                                </div>
                            }
                        </div>
                    </Form.Label>
                    {editMode ? (
                        <Form.Control
                            style={{ marginBottom: 5 }}
                            as="select"
                            className="form-control"
                            value={selectedEquipe || 0}
                            custom
                            onChange={(e) => {
                                setGuardGerente({id: 0, nome: ""})
                                setGuardAtendente({id: 0, nome:""})
                                setGerentes([])
                                setAtendentes([])
                                setSelectedGerente(0)
                                setSelectedAtendente(0)
                                handlesetGerentes(Number(e.target.value))
                            }}
                        >
                            <option value="0">Distribuir...</option>
                            {equipes.map((equipe) => (
                                <option key={equipe.id_equipe} value={equipe.id_equipe}>
                                    {equipe.nome_equipe}
                                </option>
                            ))}
                        </Form.Control>
                    ) : selectedEquipe ? equipeSelecionada !== undefined ? (
                            <p style={{ fontSize: 14 }} key={equipeSelecionada.id_equipe}>
                                {equipeSelecionada.nome_equipe}
                            </p>
                        ) : (
                            <p style={{ fontSize: 14 }}>
                            Equipe inativa
                        </p>
                        )
                    : <p style={{ fontSize: 14 }}>
                            Não atribuído
                        </p>
                    }
                </Form.Group>
            </Row>
            <Row className="row-cadastro">
                <Form.Group as={Col} md={12}>
                    <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>
                            {typeEquipe == "A" ? 'Gestor Pré Atendimento:' : 'Gerente PDV:'}
                            {typeEquipe == "A" && [25, 26].includes(typeUser) || typeEquipe == "P" && [7, 8].includes(typeUser) ? (
                                <DommusRequiredSpan />
                            ) : (
                                <></>
                            )}
                        </div>
                    </Form.Label>
                    {editMode ? (
                        <Form.Control
                            style={{ marginBottom: 5 }}
                            as="select"
                            className="form-control"
                            value={selectedGerente || 0}
                            custom
                            onChange={(e) => {
                                setGuardGerente({id: 0, nome: ""})
                                setGuardAtendente({id: 0, nome: ""})
                                setAtendentes([])
                                setSelectedAtendente(0)
                                handleSetAtendentes(Number(e.target.value))
                            }}
                        >
                            <option value="0">Distribuir...</option>
                            {gerentes.length ? gerentes.map((gerente) => gerente.ativo ? (
                                <option key={gerente.id_usuario} value={gerente.id_usuario}>
                                    {gerente.nome}
                                </option>
                            ) : (
                                <option style={{ cursor: "no-drop" }} className="pointerInativo" disabled title="Usuário Inativo" key={gerente.id_usuario} value={gerente.id_usuario}>
                                    {gerente.nome}
                                </option>
                            )) : guardGerente.nome ? (
                                <option key={guardGerente.id} value={guardGerente.id}>
                                    {guardGerente.nome}
                                </option>
                            ) : (
                                <></>
                            )}
                        </Form.Control>
                    ) : selectedGerente && gerentes.length ? gerentes.map((gerente) => gerente.id_usuario == selectedGerente ? (
                            <p style={{ fontSize: 14 }} key={gerente.id_usuario}>
                                {gerente.nome}
                            </p>
                        ) : (
                            <></>
                        )
                    ) : (
                        <p style={{ fontSize: 14 }}>
                            {`${guardGerente.nome ? guardGerente.nome : 'Não Atribuído'}`}
                        </p>
                    )}
                </Form.Group>
            </Row>
            <Row className="row-cadastro">
                <Form.Group as={Col} md={12}>
                    <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>
                            {typeEquipe == "A" ? 'Atendente:' : 'Corretor:'}
                            {[25, 26].includes(typeUser) ? (
                                <DommusRequiredSpan />
                            ) : (
                                <></>
                            )}
                        </div>
                    </Form.Label>
                    {editMode ? (
                        <Form.Control
                            style={{ marginBottom: 5 }}
                            as="select"
                            className="form-control select-atendente"
                            value={selectedAtendente || 0}
                            custom
                            onChange={(e) => setSelectedAtendente(Number(e.target.value))}
                        >
                            <option value="0">Distribuir...</option>
                            {atendentes.length ? atendentes.map((atendente) => atendente.ativo ? (
                                <option key={atendente.id_usuario} value={atendente.id_usuario}>
                                    {atendente.nome}
                                </option>
                            ) : (
                                <option style={{ cursor: "no-drop" }} className="pointerInativo" disabled title="Usuário Inativo" key={atendente.id_usuario} value={atendente.id_usuario}>
                                    {atendente.nome}
                                </option>
                            )) : guardAtendente.nome ? (
                                <option key={guardAtendente.id} value={guardAtendente.id}>
                                    {guardAtendente.nome}
                                </option>
                            ) : (
                                <></>
                            )}
                        </Form.Control>
                    ) : ( selectedAtendente && atendentes.length ? atendentes.map((atendente) => atendente.id_usuario == Number(selectedAtendente) ? (
                            <p style={{ fontSize: 14 }} key={atendente.id_usuario}>
                                {atendente.nome}
                            </p>
                        ) : (
                            <React.Fragment key={atendente.id_usuario}></React.Fragment>
                        )
                    ) : (
                        <p style={{ fontSize: 14 }}>
                            {guardAtendente.nome ? guardAtendente.nome : 'Não atribuído'}
                        </p>
                    ))}
                </Form.Group>
            </Row>
        </>
    )
}
