import React from 'react';
import './style.css';
import ReactTooltip from "react-tooltip";

export default function DommusSwitch({label="", checked=false, tooltip="", clickEvent, labelDesativado="", style={}}) {
    return (
        <>
            <ReactTooltip effect="solid"/>
            <label className="form-switch" style={style}>
                <span onClick={clickEvent}>{labelDesativado}</span>
                <input type="checkbox" checked={checked} defaultChecked={checked}/>
                <i onClick={clickEvent} className="ml-2" data-tip={tooltip}></i>
                <span onClick={clickEvent}>{label}</span>
            </label>
        </>
    )
}
