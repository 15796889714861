import React, { createContext, SetStateAction, useEffect, useState} from 'react';
import guService from '../../services/GuService';
import moment from 'moment';
import { GlobalOportunidadeContextService } from '../../services/GlobalOportunidadeContextService';
import { EmpreendimentoType } from '../../pages/DetalheOportunidade/Cadastro/DistributionForm';
import { DadosGraficoType, DadoSubGraficoType, EmpreendimentoPortalCorretorType, IconeType, LocalAtendimentoType, LocationType, OportunidadeByStatusType, OportunidadeContextFiltersType, ParticipanteType, SubmitHandlerType, TipoTarefaType, TipoVisitaType } from './types';
import { CoordenadoresType, CorretorType, DesistenciaTipoType, EquipeType, GerenteEquipeType } from '../../pages/OfertaAtiva/ModalOferta/BaseDeLeads/Filtros/types';
import { EtapaType } from '../../pages/Oportunidades/types';
import { Option } from 'react-multi-select-component';
import { CampanhaType, MidiaType, OportunidadeType, OrigemType, TipoAtendimentoType } from '../../pages/DetalheOportunidade/Cadastro/types';
import { GerenteType } from '../../pages/EquipesPrePdvSelectIdComponent/types';
import equipeService from '../../services/EquipeService';
import { OportunidadePorStatusPaginadoType } from '../../pages/Oportunidades/Visualizacao/FunilOportunidades/types';
import { NivelInteresseType } from '../FormFiltroPadrao/types';
import ParticipanteService from '../../services/ParticipanteService';
import { trackPromise } from 'react-promise-tracker';
import oportunidadeService from '../../services/OportunidadeService';
import Swal from 'sweetalert2';
import ConfiguracoesGestaoService from '../../services/ConfiguracoesGestaoService';

interface EmpreendimentoContextProps {
  dadosEmpreendimentoPortal: Array<EmpreendimentoPortalCorretorType>
  setdadosEmpreendimentoPortal: (val: Array<EmpreendimentoPortalCorretorType>) => void
  dadosOpvsSelecionadasAltMassa: Array<OportunidadePorStatusPaginadoType>
  setdadosOpvsSelecionadasAltMassa: (val: Array<OportunidadePorStatusPaginadoType>) => void
  dadosLocation: LocationType
  setdadosLocation: (val: LocationType) => void
  selectTodasOpvs: Array<boolean>
  setselectTodasOpvs: (val: Array<boolean>) => void
  statusOportunidadetodasOpvs: number
  setstatusOportunidadetodasOpvs: (val: number) => void
  gerentes: Array<GerenteEquipeType>,
  setGerentes: (val: Array<GerenteEquipeType>) => void
  resolverNomeGerente: (val: number) => String
  operadores: Array<CorretorType>
  setOperadores: (val: Array<CorretorType>) => void
  resolverNomeOperador: (val: number) => String
  listaIcones: Array<IconeType>
  setListaIcones: (val: Array<IconeType>) => void
  leadCriado: boolean
  setLeadCriado: (val: boolean) => void
  iconesPageAtual: number
  setIconesPageAtual: (val: number) => void,
  empreendimentos: EmpreendimentoType[]
  resolverNomeEmpreendimento: (val: number) => String
  setEmpreendimentos: React.Dispatch<SetStateAction<EmpreendimentoType[]>>
  equipes: EquipeType[]
  setEquipes: React.Dispatch<SetStateAction<EquipeType[]>>
  coordenadores: CoordenadoresType[]
  setCoordenadores: React.Dispatch<SetStateAction<CoordenadoresType[]>>
  resolverNomeEquipe: (val: number) => String
  optionsTiposUsuarios: Option[]
  setOptionsTiposUsuarios: React.Dispatch<SetStateAction<Option[]>>
  optionsUsuarios: Option[]
  setOptionsUsuarios: React.Dispatch<SetStateAction<Option[]>>
  equipesVisiveis: EquipeType[]
  setEquipesVisiveis: React.Dispatch<React.SetStateAction<EquipeType[]>>
  equipesEmpreendimentoUsuario: EquipeType[]
  setEquipesEmpreendimentoUsuario: React.Dispatch<React.SetStateAction<EquipeType[]>>
  idEmpreendimentoSelecionado: number
  setIdEmpreendimentoSelecionado:  React.Dispatch<React.SetStateAction<number>>
  empreendimentosVisiveis: EmpreendimentoType[]
  setEmpreendimentosVisiveis: React.Dispatch<React.SetStateAction<EmpreendimentoType[]>>
  indiceAtualDaEtapaSelecionada: null | number;
  setIndiceAtualDaEtapaSelecionada: React.Dispatch<any>
}

interface OportunidadeContextProps {
  checked: boolean
  setChecked: React.Dispatch<SetStateAction<boolean>>
  data: DadosGraficoType[]
  dataSubCategorias: DadoSubGraficoType[]
  colunas: EtapaType[]
  categorias: string[]
  titulo: string
  setTitulo: React.Dispatch<SetStateAction<string>>
  filtrosConfig: OportunidadeContextFiltersType,
  setFiltrosConfig: (val: OportunidadeContextFiltersType) => void
  oportunidades: Array<OportunidadeByStatusType>
  setOportunidades: (val: Array<OportunidadeByStatusType>) => void
  totalVisualizacoes: number
  setTotalVisualizacoes: (val: number) => void
  promiseFiltro: (filtros: OportunidadeContextFiltersType) => void
  buscarFiltroLista: (filtros: OportunidadeContextFiltersType) => void
  filtros: OportunidadeContextFiltersType
  setFiltros: (val: OportunidadeContextFiltersType) => void
  modeloVisualizacao: string
  setModeloVisualizacao: (val: string) => void
  buscarFiltroFunil: (filtros: OportunidadeContextFiltersType) => void
  funil: boolean
  setFunil: (val: boolean) => void
  filtroFunil: OportunidadeContextFiltersType
  setFiltroFunil: (val: OportunidadeContextFiltersType) => void
  redirect: string
  redirectToHome: (redirect: string) => void
  campanhas: CampanhaType[]
  setCampanhas: React.Dispatch<SetStateAction<CampanhaType[]>>
  origens: OrigemType[]
  setOrigens: React.Dispatch<SetStateAction<OrigemType[]>>
  midias: MidiaType[]
  setMidias: React.Dispatch<SetStateAction<MidiaType[]>>
  tiposAtendimento: TipoAtendimentoType[]
  setTiposAtendimento: React.Dispatch<SetStateAction<TipoAtendimentoType[]>>
  tiposTarefa: TipoTarefaType[]
  setTiposTarefa: React.Dispatch<React.SetStateAction<TipoTarefaType[]>>
  niveisInteresse: NivelInteresseType[]
  setNiveisInteresse: React.Dispatch<React.SetStateAction<NivelInteresseType[]>>
  tiposDesistencia: DesistenciaTipoType[]
  setTiposDesistencia: React.Dispatch<React.SetStateAction<DesistenciaTipoType[]>>
  oportunidadeSelecionada: OportunidadeType|null
  setOportunidadeSelecionada: React.Dispatch<React.SetStateAction<OportunidadeType|null>>
  locaisAtendimento: LocalAtendimentoType[]
  setLocaisAtendimento: React.Dispatch<React.SetStateAction<LocalAtendimentoType[]>>
  tiposVisita: TipoVisitaType[]
  setTiposVisita: React.Dispatch<React.SetStateAction<TipoVisitaType[]>>
  novoTipoAtendimento: number;
  setNovoTipoAtendimento: React.Dispatch<React.SetStateAction<number>>
  participantes: ParticipanteType[];
  setParticipantes: React.Dispatch<React.SetStateAction<ParticipanteType[]>>
  resolverNomeCampanha: (idCampanha: number) => undefined|string
  atualizarOportunidade: (idOportunidade:number) => void;
  visitasNaoFinalizadas: number[];
  setVisitasNaoFinalizadas: React.Dispatch<React.SetStateAction<number[]>>
  exibirInformacoesMeta: boolean | null,
  setExibirInformacoesMeta: React.Dispatch<React.SetStateAction<boolean | null>>,
  buscarConfiguracaoExibirDadosAnuncioMeta: () => void;
}

export const EmpreendimentoContext = createContext({} as EmpreendimentoContextProps);

export const OportunidadeContext = createContext({} as OportunidadeContextProps);

export const GlobalContext = ({children}: {children: JSX.Element}) => {
    const [dadosEmpreendimentoPortal, setdadosEmpreendimentoPortal] = useState([] as Array<EmpreendimentoPortalCorretorType>)
    const [dadosLocation, setdadosLocation] = useState({} as LocationType)
    const [empreendimentos, setEmpreendimentos] = useState<EmpreendimentoType[]>([])
    const [mapaEmpreendimentos, setMapaEmpreendimentos] = useState<Map<number, EmpreendimentoType>>(new Map())
    const [dadosOpvsSelecionadasAltMassa, setdadosOpvsSelecionadasAltMassa] = useState([] as Array<OportunidadePorStatusPaginadoType>)
    const [selectTodasOpvs, setselectTodasOpvs] = useState([false])
    const [statusOportunidadetodasOpvs, setstatusOportunidadetodasOpvs] = useState(0)
    const [gerentes, setGerentes] = useState([] as Array<GerenteEquipeType>)
    const [mapaGerentes, setMapaGerentes] = useState<Map<Number, GerenteType>>(new Map())
    const [operadores, setOperadores] = useState([] as Array<CorretorType>)
    const [mapaOperadores, setMapaOperadores] = useState<Map<Number, CorretorType>>(new Map())
    const [equipes, setEquipes] = useState<EquipeType[]>([])
    const [coordenadores, setCoordenadores] = useState<CoordenadoresType[]>([])
    const [mapaEquipes, setMapaEquipes] = useState<Map<Number, EquipeType>>(new Map())
    const [leadCriado, setLeadCriado] = useState(false)
    const [listaIcones, setListaIcones] = useState([] as Array<IconeType>)
    const [iconesPageAtual, setIconesPageAtual] = useState(2)
    const [optionsTiposUsuarios, setOptionsTiposUsuarios] = useState<Option[]>([])
    const [optionsUsuarios, setOptionsUsuarios] = useState<Option[]>([])
    const [equipesVisiveis, setEquipesVisiveis] = useState<EquipeType[]>([])
    const [equipesEmpreendimentoUsuario, setEquipesEmpreendimentoUsuario] = useState<EquipeType[]>([])
    const [idEmpreendimentoSelecionado, setIdEmpreendimentoSelecionado] = useState(0)
    const [empreendimentosVisiveis, setEmpreendimentosVisiveis] = useState<EmpreendimentoType[]>([])

  //Dependencias da OportunidadeContext
  const [filtrosConfig, setFiltrosConfig] = useState({} as OportunidadeContextFiltersType);
  const [oportunidades, setOportunidades] = useState([] as Array<OportunidadeByStatusType>);
  const [totalVisualizacoes, setTotalVisualizacoes] = useState(0);
  const [filtros, setFiltros] = useState({} as OportunidadeContextFiltersType)
  const [modeloVisualizacao, setModeloVisualizacao] = useState(guService.getLocalState("exibicaoLista") || "")
  const [funil, setFunil] = useState(false);
  const [filtroFunil, setFiltroFunil] = useState({} as OportunidadeContextFiltersType);
  const [redirect, setRedirect] = useState(guService.getLocalState("redirect") || "")
  const [titulo, setTitulo] = useState("")
  const [categorias, setCategorias] = useState<string[]>([])
  const [colunas, setColunas] = useState<EtapaType[]>([])
  const [data, setData] = useState<DadosGraficoType[]>([])
  const [dataSubCategorias, setDataSubCategorias] = useState<DadoSubGraficoType[]>([])
  const [checked, setChecked] = useState(false)
  const [midias, setMidias] = useState<MidiaType[]>([])
  const [origens, setOrigens] = useState<OrigemType[]>([])
  const [campanhas, setCampanhas] = useState<CampanhaType[]>([])
  const [tiposAtendimento, setTiposAtendimento] = useState<TipoAtendimentoType[]>([])
  const [tiposTarefa, setTiposTarefa] = useState<TipoTarefaType[]>([])
  const [niveisInteresse, setNiveisInteresse] = useState<NivelInteresseType[]>([])
  const [tiposDesistencia, setTiposDesistencia] = useState<DesistenciaTipoType[]>([])
  const [oportunidadeSelecionada, setOportunidadeSelecionada] = useState<OportunidadeType|null>(null)
  const [locaisAtendimento, setLocaisAtendimento] = useState<LocalAtendimentoType[]>([])
  const [tiposVisita, setTiposVisita] = useState<TipoVisitaType[]>([])
  const [novoTipoAtendimento, setNovoTipoAtendimento] = useState(0);
  const [participantes, setParticipantes] = useState<ParticipanteType[]>([]);
  const [indiceAtualDaEtapaSelecionada,setIndiceAtualDaEtapaSelecionada] = useState(null);
  const [visitasNaoFinalizadas, setVisitasNaoFinalizadas] = useState<number[]>([]);
  const [exibirInformacoesMeta, setExibirInformacoesMeta] = useState<boolean|null>(null);

  useEffect(() => {
    let filtros = {
      oportunidade: {
        data_inicial: "",
        data_final: "",
      },
      exportar: false,
      transfers: {
        transfer: false,
        period: ''
      },
      discardeds: {
        discarded: false,
        period: ''
      },
      attendance: {
        id_tipo_atendimento: "",
        period: ''
      }
    } as OportunidadeContextFiltersType

    if(redirect == 'allOpvs') {
      handleSubmit(filtros)
    } else if(redirect == 'opvsOfTheDay') {
      const today = moment().format("YYYY-MM-DD")
      if(!filtros.oportunidade) return
      filtros.oportunidade.data_inicial =  today
      filtros.oportunidade.data_final = today
      handleSubmit(filtros)
    } else if(redirect == 'opvsFromTransfers') {
      if(!filtros.transfers) return
      filtros.transfers.transfer = true
      handleSubmit(filtros)
    } else if(redirect == 'opvsFromTransfersOfTheDay') {
      if(!filtros.transfers) return
      filtros.transfers.transfer = true
      filtros.transfers.period = 'day'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithDiscardedLeads') {
      if(!filtros.discardeds) return
      filtros.discardeds.discarded = true
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithDiscardedLeadsOfTheDay') {
      if(!filtros.discardeds) return
      filtros.discardeds.discarded = true
      filtros.discardeds.period = 'day'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceTask') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '5'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceTaskToDo') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '5'
      filtros.attendance.period = 'today'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceDelayedTask') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '5'
      filtros.attendance.period = 'delayed'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceVisit') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '4'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceVisitToDo') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '4'
      filtros.attendance.period = 'today'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceDelayedVisit') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '4'
      filtros.attendance.period = 'delayed'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendancePhone') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '1'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendancePhoneOfTheDay') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '1'
      filtros.attendance.period = 'today'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceEmail') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '2'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceEmailOfTheDay') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '2'
      filtros.attendance.period = 'today'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceWhatsapp') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '3'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceWhatsappOfTheDay') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '3'
      filtros.attendance.period = 'today'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceProposal') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '6'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceProposalOfTheDay') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '6'
      filtros.attendance.period = 'today'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceComment') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '7'
      handleSubmit(filtros)
    } else if(redirect == 'opvsWithAttendanceCommentOfTheDay') {
      if(!filtros.attendance) return
      filtros.attendance.id_tipo_atendimento = '7'
      filtros.attendance.period = 'today'
      handleSubmit(filtros)
    }
  }, [redirect])

  useEffect(()=>{
    if(guService.getLocalState('token')){
      Promise.all([
        equipeService.buscarEquipesSemEmpreendimento(),
        equipeService.buscarGerentes(),
        equipeService.buscarOperadores(),
        ParticipanteService.buscar()
      ]).then((response)=>{
        setEquipes(response[0].data)
        setGerentes(response[1].data)
        setOperadores(response[2].data)
        setParticipantes(response[3].data)
      });
    }

  },[])

  const buscarConfiguracaoExibirDadosAnuncioMeta = ()=>{
    ConfiguracoesGestaoService.buscarConfiguracao('exibir_dados_anuncios_meta').then((response) => {
      if (response.data !== "") {
          setExibirInformacoesMeta((response.data == 'true' || response.data == true || response.data == 1 || response.data == '1') ? true : false)
      }else{
          setExibirInformacoesMeta(false)
      }
    })
  }

  useEffect(() => {
    let filtrosOportunidade = filtroFunil
    if(!filtrosOportunidade?.oportunidade) return
    filtrosOportunidade.oportunidade.expirados_desistencias = !checked
    setFiltroFunil(filtrosOportunidade)
    buscarFiltroFunil(filtroFunil)
  }, [checked])

  useEffect(()=>{
    const novoMapa = new Map();
    Array.isArray(empreendimentos) &&empreendimentos.forEach((empreendimento) => {
      novoMapa.set(Number(empreendimento.id), empreendimento);
    });
    setMapaEmpreendimentos(novoMapa);
  }, [empreendimentos]);

  useEffect(()=>{
    const novoMapa = new Map();
    Array.isArray(gerentes) && gerentes.forEach((gerente) => {
      novoMapa.set(Number(gerente.id_gerente), gerente);
    });
    setMapaGerentes(novoMapa);
  }, [gerentes]);

  useEffect(()=>{
    const novoMapa = new Map();
    Array.isArray(operadores) && operadores.forEach((operador) => {
      novoMapa.set(Number(operador.id_operador), operador);
    });
    setMapaOperadores(novoMapa);
  }, [operadores]);

  useEffect(()=>{
    const novoMapa = new Map();
    Array.isArray(equipes) && equipes.forEach((equipe) => {
      novoMapa.set(Number(equipe.id_equipe), equipe);
    });
    setMapaEquipes(novoMapa);
  }, [equipes]);

  function resolverNomeEmpreendimento(idEmpreendimento:number, padrao = "Não Atribuído"){
    let empreendimento = mapaEmpreendimentos.get(Number(idEmpreendimento));
    return (empreendimento && empreendimento.nome) || padrao;
  };

  function resolverNomeGerente(idGerente:number, padrao = "Não Atribuído"){
    let gerente = mapaGerentes.get(Number(idGerente));
    return (gerente && gerente.nome_gerente) || padrao;
  };

  function resolverNomeOperador(idCorretor:number, padrao = "Não Atribuído"){
    let corretor = mapaOperadores.get(Number(idCorretor));
    return (corretor && corretor.nome_operador) || padrao;
  };

  function resolverNomeEquipe(idEquipe:number, padrao = "Não Atribuído"){
    let equipe = mapaEquipes.get(Number(idEquipe));
    return (equipe && equipe?.nome_equipe) || padrao;
  };

  function handleSubmit(filtros: OportunidadeContextFiltersType) {
    GlobalOportunidadeContextService.submitHandler({
      filtros,
      setFiltrosConfig,
      setOportunidades,
      setTotalVisualizacoes,
      setFiltros,
      modeloVisualizacao,
      setFunil,
      setFiltroFunil} as SubmitHandlerType)
  }

  function promiseFiltro(filtros: OportunidadeContextFiltersType) {
    GlobalOportunidadeContextService.promiseFiltro({filtros, setFiltrosConfig, setOportunidades, setTotalVisualizacoes})
  }

  function buscarFiltroFunil(filtros: OportunidadeContextFiltersType) {
    GlobalOportunidadeContextService.buscarFiltroFunil({
      filtros,
      setFunil,
      setFiltroFunil,
      modeloVisualizacao,
      setOportunidades,
      setTotalVisualizacoes,
      setTitulo,
      setCategorias,
      setColunas,
      setData,
      setDataSubCategorias,
      checked
    })
  }

  function buscarFiltroLista(filtros: OportunidadeContextFiltersType) {
    GlobalOportunidadeContextService.buscarFiltroLista({filtros, setFiltros, modeloVisualizacao, setOportunidades, setTotalVisualizacoes})
  }

  function redirectToHome(redirect="") {
    if(window.location.href.split('/')[3]) {
      window.location.href = '/'
    } else {
      setRedirect(redirect)
    }
  }

  function resolverNomeCampanha(idCampanha: number) {
    return campanhas.find(campanha => campanha.id_campanha == idCampanha)?.nome
  }

  function atualizarOportunidade(idOportunidade: number){
    trackPromise(
      oportunidadeService.buscarOportunidade(idOportunidade).then(
        (response) => {
          setOportunidadeSelecionada(response.data);
        },
        (error) => {
          Swal.fire({
            titleText: "Sem permissão de acesso à oportunidade",
            text: "Verifique se você já teve acesso a esta oportunidade ou se realizou a transferência da mesma para outro atendente",
            icon: "warning",
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Voltar para lista de oportunidades",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              window.location.replace("/");
            }
          });
        }
      )
    )
  }

    return (
        <>
            <EmpreendimentoContext.Provider //value={{ nome: "Isa Linda" }}
                value={{dadosEmpreendimentoPortal, setdadosEmpreendimentoPortal,
                    dadosOpvsSelecionadasAltMassa, setdadosOpvsSelecionadasAltMassa,
                    dadosLocation, setdadosLocation, selectTodasOpvs, setselectTodasOpvs,
                    statusOportunidadetodasOpvs, setstatusOportunidadetodasOpvs,
                    gerentes, setGerentes, resolverNomeGerente,
                    operadores, setOperadores, resolverNomeOperador,
                    listaIcones, setListaIcones,
                    leadCriado, setLeadCriado,
                    iconesPageAtual,
                    setIconesPageAtual,
                    empreendimentos,
                    resolverNomeEmpreendimento,
                    setEmpreendimentos,
                    equipes, resolverNomeEquipe,
                    setEquipes,
                    optionsUsuarios,
                    setOptionsUsuarios,
                    optionsTiposUsuarios,
                    setOptionsTiposUsuarios,
                    equipesVisiveis,
                    setEquipesVisiveis,
                    equipesEmpreendimentoUsuario,
                    setEquipesEmpreendimentoUsuario,
                    idEmpreendimentoSelecionado,
                    setIdEmpreendimentoSelecionado,
                    empreendimentosVisiveis,
                    setEmpreendimentosVisiveis,
                    coordenadores, 
                    setCoordenadores,
                    indiceAtualDaEtapaSelecionada,
                    setIndiceAtualDaEtapaSelecionada
                }}
            >
                <OportunidadeContext.Provider
                    value={{
                        checked,
                        setChecked,
                        data,
                        dataSubCategorias,
                        colunas,
                        categorias,
                        titulo,
                        setTitulo,
                        filtrosConfig,
                        setFiltrosConfig,
                        oportunidades,
                        setOportunidades,
                        totalVisualizacoes,
                        setTotalVisualizacoes,
                        promiseFiltro,
                        buscarFiltroLista,
                        filtros,
                        setFiltros,
                        modeloVisualizacao,
                        setModeloVisualizacao,
                        buscarFiltroFunil,
                        funil,
                        setFunil,
                        filtroFunil,
                        setFiltroFunil,
                        redirect,
                        redirectToHome,
                        campanhas,
                        setCampanhas,
                        origens,
                        setOrigens,
                        midias,
                        setMidias,
                        tiposAtendimento,
                        setTiposAtendimento,
                        tiposTarefa,
                        setTiposTarefa,
                        niveisInteresse,
                        setNiveisInteresse,
                        tiposDesistencia,
                        setTiposDesistencia,
                        oportunidadeSelecionada,
                        setOportunidadeSelecionada,
                        locaisAtendimento,
                        setLocaisAtendimento,
                        tiposVisita,
                        setTiposVisita,
                        novoTipoAtendimento,
                        setNovoTipoAtendimento,
                        resolverNomeCampanha,
                        participantes, 
                        setParticipantes,
                        atualizarOportunidade,
                        visitasNaoFinalizadas,
                        setVisitasNaoFinalizadas,
                        exibirInformacoesMeta,
                        setExibirInformacoesMeta,
                        buscarConfiguracaoExibirDadosAnuncioMeta,
                    }}
                >
                    {children}
                </OportunidadeContext.Provider>
            </EmpreendimentoContext.Provider>,

            {/* <OpvsSelecionadasContext.Provider value={{ nome: "Isa Linda" }}
                //value={{dadosOpvsSelecionadasAltMassa, setdadosOpvsSelecionadasAltMassa}}
            >
                {children}
            </OpvsSelecionadasContext.Provider> */}
        </>
    );
};
