import React, { useState, useEffect, useCallback, useContext } from "react";
import { Form, Row, Container, Col } from "react-bootstrap";
import "../NovoAnexo/index.css";
import OportunidadeService from "../../../../services/OportunidadeService";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../../components/DommusToast";
import ValidaTipoArquivo from '../../../../helpers/ValidaTipoArquivo'
import { TiposDocumentosContext } from "../../../../contexts/TiposDocumentosContext/TiposDocumentosContext";
import { OportunidadeContext } from "../../../../components/GlobalContext/globalContext";

export default function NovoAnexo({
  oportunidade,
  inserirLista,
  anexos,
  refresh,
}) {
  const [tipoAnexoSelecionado, setTipoAnexoSelecionado] = useState(0);
  const [nomeDestino, setNomeDestino] = useState(null);
  const [tiposDocumentosOptions, setTiposDocumentosOptions] = useState([]);
  const {tiposDocumentos} = useContext(TiposDocumentosContext);
  const {atualizarOportunidade} = useContext(OportunidadeContext);

  const [arquivo, setArquivo] = useState({});

  const [idEnvolvido, setIdEnvolvido] = useState(null);

  //filtra lista de tipos de documentos ao alterar o envolvido
  useEffect(() => {
    if (!idEnvolvido || idEnvolvido === "Selecione:") {
      setTiposDocumentosOptions([]);
      return;
    }

    const envolvidoTestar = Number(idEnvolvido);
    const documentosDisponiveisEnvolvido = (tiposDocumentos && tiposDocumentos.filter(
      (tipoDocumento) => {
        const idDocumento = Number(tipoDocumento.id);
        return !(anexos && anexos.some(
          (anexo) => (Number(anexo.envolvido?.id_envolvido) == envolvidoTestar) &&
          (Number(anexo.id_documento) === idDocumento)
        ));
      })) || [];
    setTiposDocumentosOptions(documentosDisponiveisEnvolvido);
  }, [idEnvolvido, anexos]);

  const montaArquivo = useCallback((event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    if(ValidaTipoArquivo.verificaTipoArquivo(file)){
      reader.onload = function (upload) {
        let dadosArquivo = {
          conteudo: upload.target.result.split(/base64,/)[1],
          nome: file.name,
        };
        setArquivo(dadosArquivo);
        
      };
      reader.readAsDataURL(file);
    }else{
      errorToast.fire({
        text: "Tipo de arquivo não suportado, selecione outro ou verifique o mesmo"
      })
    }
  }, []);

  useEffect(() => {
    let documentoEncontrado = tiposDocumentosOptions.find(
      (documento) => documento.id === tipoAnexoSelecionado
    );
    if (documentoEncontrado) {
      setNomeDestino(documentoEncontrado.orientacao_descricao);
    } else {
      setNomeDestino([]);
    }
  }, [tipoAnexoSelecionado]);

  function salvarAnexo() {
    var dados = {
      id_documento: parseInt(tipoAnexoSelecionado),
      dadosArquivo: arquivo,
      id_oportunidade: oportunidade.id_oportunidade,
      id_envolvido: parseInt(idEnvolvido),
    };
    dados.dadosArquivo.nome_destino = nomeDestino;

    trackPromise(
      OportunidadeService.salvarAnexo(dados).then(
        (response) => {
          Swal.fire({
            titleText: "Cadastrado!",
            text: "Anexo carregado com sucesso.",
            icon: "success",
          }).then(() => {
            if (!(dados.id_oportunidade_atendimento || false)) {
              inserirLista(response.data);
              refresh();
              atualizarOportunidade(oportunidade.id_oportunidade);
            }
          });
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar o arquivo: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  return (
    <>
      <Container fluid>
        <Form className="formCadastro">
          <Row>
            <Form.Group as={Col} controlId="formEnvolvido">
              <Form.Label>Envolvido Relacionado</Form.Label>
              <Form.Control
                as="select"
                defaultValue="Selecione"
                onChange={(event) => {
                  setIdEnvolvido(event.target.value);
                }}
              >
                <option>Selecione:</option>
                {oportunidade.envolvidos_oportunidade ? (
                  oportunidade.envolvidos_oportunidade.map((item) => (
                    <option
                      value={parseInt(item.envolvido.id_envolvido)}
                      key={item.envolvido.id_envolvido}
                    >
                      {item.envolvido.nome}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Tipo de Documento</Form.Label>
              <Form.Control
                as="select"
                defaultValue="Selecione"
                onChange={(event) => {
                  setTipoAnexoSelecionado(event.target.value);
                }}
              >
                <option>Selecione:</option>
                {tiposDocumentosOptions.length > 0 ? (
                  tiposDocumentosOptions.map((item) => (
                    <option value={parseInt(item.id)} key={item.id}>
                      {item.descricao}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formNome">
              <div className="av-item select-file">
                <div className="documentacao-item-wrapper">
                  <div className="form-group">
                    <div>
                      <h6>Arquivo:</h6>
                    </div>
                    <div className="upload-wrapper">
                      <div id="documento-0" className="documento-wrapper">
                        <div className="ajax-upload-dragdrop">
                          <span>
                            <Form.Control
                              type="file"
                              accept="application/pdf, image/png, image/jpeg"
                              className="form-control-file"
                              onChange={montaArquivo}
                            />
                          </span>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
          </Row>

          <Row className="formFooter">
            <Col>
              <button
                type="button"
                onClick={() => salvarAnexo()}
                className="btn btn-success"
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ marginRight: "15px" }}
                />
                Salvar
              </button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}
