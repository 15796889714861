import { trackPromise } from 'react-promise-tracker';
import crm from './crm';
import { swalErro } from '../helpers/DommusSwal';

const relatorioService = {
    relatorioGeral: (filtros = null) => {
        return crm.get('/relatorio/oportunidade/geral', {params: filtros});
    },
    relatorioOfertaAtivaResultado: (filtros = null) => {
        return crm.get('/relatorio/oferta-ativa', {params: filtros, responseType: 'blob'});
    },
    relatorioOportunidadesDiario: (tipoRelatorio=null) => {
        return crm.get('/relatorio/oportunidade', { params: { tipoRelatorio } })
    },
    baixarRelatorioOportunidadesDiario: (tipoRelatorio=null) => {
        trackPromise(relatorioService.relatorioOportunidadesDiario(tipoRelatorio))
            .then(resposta => {
              if(resposta?.data?.urlArquivo){
               trackPromise( crm.get(resposta?.data?.urlArquivo)).then(res => {
                const blob = new Blob([res?.data?.body], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', (res?.data?.nome ?? 'dados.csv')); // Nome do arquivo
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link); 
               })
              }
            })
            .catch(erro => swalErro(`Houve um erro ao tentar baixar o relatório de oportunidades. Erro: ${erro.response?.data?.message} \nStatus: ${erro.response.status} \n${erro}`))
    },
    buscarRelatoriosDiversos: () => {
        return crm.get('/relatorio/diversos')
    },
    buscarFiltrosRelatorios: (id_relatorio) => {
        return crm.get('/relatorio/diversos/filtros/' + id_relatorio)
    },
    pesquisarDiversos: (filtros) => {
        return crm.post('/relatorio/diversos/pesquisar', filtros)
    },
    exportarDiversos: (filtros) => {
        return crm.post('/relatorio/diversos/exportar', filtros)
    },
};

export default relatorioService;
