import React from "react";
import "./index.css";
import { FormProvider, useForm } from "react-hook-form";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { QuestionarioPublicoType } from "../../../../../../contexts/QuestionarioContext/types";
import { PerguntaType, TipoRespostaEnum } from "../../../../../../contexts/PerguntaContext/types";
import { apenasNumeros } from "../../../../../../components/InputTextMask";
import { PerguntaItemQuestionarioPublicoForm } from "./PerguntaItemQuestionarioPublicoForm";
import { trackPromise } from "react-promise-tracker";
import QuestionarioService from "../../../../../../services/QuestionarioService";
import Swal from "sweetalert2";
import { swalSucesso } from "../../../../../../helpers/DommusSwal";

interface QuestionarioPublicoOportunidadeFormProps{
  questionarioPublico: QuestionarioPublicoType;
  perguntas?: PerguntaType[];
}

export function QuestionarioPublicoOportunidadeForm({questionarioPublico, perguntas}:QuestionarioPublicoOportunidadeFormProps) {
  const metodos = useForm();

  const formatarResposta = (data: any) => {
    var retorno:any = {};
    Object.keys(data).map((key) => {
      let pergunta = questionarioPublico?.questionario?.perguntas?.find(
        (pergunta) => pergunta.id_pergunta === parseInt(key)
      );
      switch (pergunta?.pergunta.id_tipo_resposta_pergunta) {
        case TipoRespostaEnum.CEP:
        case TipoRespostaEnum.CPF:
        case TipoRespostaEnum.TELEFONE:
        case TipoRespostaEnum.MOEDA:
          retorno[apenasNumeros(key).toString()] = apenasNumeros(data[key]);
          break;
        default:
          retorno[apenasNumeros(key).toString()] = data[key];
          break;
      }
    });
    return retorno;
  };

  const handleOnSubmit = (data: any) => {
    let respostasFormatadas = formatarResposta(data);
    trackPromise(
      QuestionarioService.storeRespostasQuestionarioPublico(questionarioPublico.id_questionario_publico, respostasFormatadas))
      .then((response: any) => {
        swalSucesso("Questionário respondido com sucesso!!!");
      })
      .catch((error) => {
        Swal.fire({
            titleText: "Ooops...",
            text: error?.response?.data?.message ?? ("Ocorreu um erro ao tentar salvar as respostas: " + error),
            icon: "error",
          });
      });
  };

  return (
    <FormProvider {...metodos}>
      <Form
        onSubmit={metodos.handleSubmit(handleOnSubmit)}
        className="form-questionario"
      >
        {perguntas?.length ? (
          <>
            {perguntas?.map((pergunta, index) => {
              return (
                <PerguntaItemQuestionarioPublicoForm key={index} index={index} pergunta={pergunta} questionarioPublico={questionarioPublico}/>
              );
            })}
            <Row className="btn-enviar-questionario">
              <Button variant="success" type="submit">
                <FontAwesomeIcon icon={faPaperPlane} />
                Salvar Resposta
              </Button>
            </Row>
          </>
        ) : (
          <Alert variant="warning">
            Nenhuma pergunta vinculada nesse questionário
          </Alert>
        )}
      </Form>
    </FormProvider>
  );
}
